//import { toaster } from "baseui/toast"
var ssnRegex = /^\d{9}$/;
var einRegex = /^\d{2}-\d{7}$/;

export default function Information(props: any) {
  const next = () => {
    //check if fields are filled
    if (
      props.form.phone === '' ||
      props.form.email === '' ||
      props.form.businessName === ''
    ) {
      //   toaster.negative("Please fill all fields")
      props.setErrors({
        phone: props.form.phone === '' ? 'This field is required' : '',
        email: props.form.email === '' ? 'This field is required' : '',
        businessName:
          props.form.businessName === '' ? 'This field is required' : ''
      });
      return;
    } else {
      props.setErrors({
        phone: false,
        email: false,
        businessName: false
      });
    }

    //regex to test email address
    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(props.form.email)) {
      // toaster.negative("Please enter a valid email address")
      props.setErrors({
        email: 'Please enter a valid email address'
      });
      return;
    } else {
      props.setErrors({
        email: false
      });
    }

    //regex to test usa phone number
    const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    if (!phoneRegex.test(props.form.phone)) {
      //  toaster.negative("Please enter a valid phone number")
      props.setErrors({
        phone: 'Please enter a valid phone number'
      });
      return;
    } else {
      props.setErrors({
        phone: false
      });
    }

    if (props.form.taxId !== '')
      if (!validateTaxID(props.form.taxId)) {
        //   toaster.negative("Please enter a valid tax id")
        props.setErrors({
          taxId: 'Please enter a valid tax id'
        });
        return;
      } else {
        props.setErrors({
          taxId: false
        });
      }

    props.nextPage();
  };

  const validateTaxID = (taxID: any) => {
    if (ssnRegex.test(taxID)) {
      return true;
    } else if (einRegex.test(taxID)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className='current flex-column' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label required'>Business Name</label>

            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              placeholder='Enter your business name'
              value={props.form.businessName}
              onChange={(e) =>
                props.setForm({ ...props.form, businessName: e.target.value })
              }
            />

            {props.errors.businessName && (
              <div className='fv-plugins-message-container'>
                <div
                  data-field='businessName'
                  data-validator='notEmpty'
                  className='fv-help-block'
                >
                  {props.errors.businessName}
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label'>Tax ID</label>

            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              placeholder='12-3456789'
              value={props.form.taxId}
              onChange={(e) =>
                props.setForm({ ...props.form, taxId: e.target.value })
              }
            />

            {props.errors.taxId && (
              <div className='fv-plugins-message-container'>
                <div
                  data-field='taxId'
                  data-validator='notEmpty'
                  className='fv-help-block'
                >
                  {props.errors.taxId}
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label required'>Business Phone number</label>

            <input
              className='form-control form-control-lg form-control-solid'
              type='tel'
              placeholder='888 888 888'
              value={props.form.phone}
              onChange={(e) =>
                props.setForm({ ...props.form, phone: e.target.value })
              }
            />

            {props.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div
                  data-field='phone'
                  data-validator='notEmpty'
                  className='fv-help-block'
                >
                  {props.errors.phone}
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label required'>
              Business Email Address
            </label>

            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              placeholder='John@business.com'
              value={props.form.email}
              onChange={(e) =>
                props.setForm({ ...props.form, email: e.target.value })
              }
            />

            {props.errors.email && (
              <div className='fv-plugins-message-container'>
                <div
                  data-field='email'
                  data-validator='notEmpty'
                  className='fv-help-block'
                >
                  {props.errors.email}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='d-flex pt-15 justify-content-between mb-20'>
          <button
            type='button'
            onClick={() => props.previousPage()}
            className='btn btn-lg btn-light-primary me-3'
          >
            <i className='ki-outline ki-arrow-left fs-4 me-1' />
            Previous
          </button>

          <button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={next}
          >
            Continue
            <i className='ki-outline ki-arrow-right fs-4 ms-1' />
          </button>
        </div>
      </div>
    </>
  );
}
