import axios from 'axios';

// Create axios client, pre-configured with baseURL
const Api = axios.create({
  baseURL: 'https://core.merchantstacks.com/'
  // timeout: 50000,
});

let token = localStorage.getItem('token');

Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default Api;
