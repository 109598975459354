import { Button } from 'baseui/button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import Api from '../Api';

export default function Account(props: any) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const complete = async () => {
    setLoading(true);

    try {
      await Api.post('business', props.form);

      toaster.positive('Business created');

      setLoading(false);

      window.location.href = '/dashboard';
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      const { message } = error.response.data;

      if (message) {
        toaster.negative(message);
      }
    }
  };

  return (
    <>
      <div className='current flex-column' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='pb-8 pb-lg-10'>
            <h2 className='fw-bold text-gray-900'>Your are almost done!</h2>
          </div>

          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            I agree to the{' '}
            <a
              href='https://merchantstacks.com/terms-of-service'
              target='new'
              className='fw-bolder'
            >
              terms of service
            </a>
          </Checkbox>
        </div>

        <div className='d-flex pt-15 justify-content-between mb-20'>
          <button
            type='button'
            onClick={() => props.previousPage()}
            className='btn btn-lg btn-light-primary me-3'
          >
            <i className='ki-outline ki-arrow-left fs-4 me-1' />
            Previous
          </button>

          <Button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={complete}
            isLoading={loading}
            disabled={!checked}
          >
            Submit
            <i className='ki-outline ki-arrow-right fs-4 ms-1' />
          </Button>
        </div>
      </div>
    </>
  );
}
