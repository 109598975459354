//import { toaster } from "baseui/toast"

export default function Merchants(props: any) {
  const next = () => {
    //check if fields are filled
    if (props.form.merchantServices === '' || props.form.industry === '') {
      //   toaster.negative("Please fill all fields")
      props.setErrors({
        merchantServices:
          props.form.merchantServices === '' ? 'This field is required' : '',
        industry: props.form.industry === '' ? 'This field is required' : ''
      });
      return;
    } else {
      props.setErrors({
        merchantServices: false,
        industry: false
      });
    }

    props.nextPage();
  };

  return (
    <>
      <div className='current flex-column' data-kt-stepper-element='content'>
        <div className='w-100'>
          <a
            href='https://merchantstacks.com'
            target='new'
            className='d-flex w-100 py-10'
          >
            <img
              alt='Logo'
              src='assets/media/logos/custom-1.png'
              className='h-50px'
            />
          </a>

          <div className='mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
            <label className='d-flex align-items-center form-label mb-5'>
              Do you use merchant services?
            </label>

            <div className='mb-0'>
              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label'>
                      <i className='ki-outline ki-chart fs-1 text-gray-600'></i>
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bold text-gray-800 text-hover-primary fs-5'>
                      Yes
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='option-input'
                    type='radio'
                    checked={
                      props.form?.merchantServices === 'yes' ? true : false
                    }
                    name='account_plan'
                    value='yes'
                    onChange={() =>
                      props.setForm({ ...props.form, merchantServices: 'yes' })
                    }
                  />
                </span>
              </label>

              <label className='d-flex flex-stack mb-0 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label'>
                      <i className='ki-outline ki-chart-pie-4 fs-1 text-gray-600'></i>
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bold text-gray-800 text-hover-primary fs-5'>
                      No
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='option-input'
                    type='radio'
                    name='account_plan'
                    checked={
                      props.form?.merchantServices === 'no' ? true : false
                    }
                    value='no'
                    onChange={() =>
                      props.setForm({ ...props.form, merchantServices: 'no' })
                    }
                  />
                </span>
              </label>
            </div>

            {props.errors.merchantServices && (
              <div className='fv-plugins-message-container'>
                <div
                  data-field='merchantServices'
                  data-validator='notEmpty'
                  className='fv-help-block'
                >
                  {props.errors.merchantServices}
                </div>
              </div>
            )}
          </div>

          <div
            className='fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'
            data-select2-id='select2-data-11-mejc'
          >
            <label className='form-label required'>
              What is your industry?
            </label>

            <select
              name='industry'
              className='form-select form-select-lg form-select-solid'
              onChange={(e) =>
                props.setForm({ ...props.form, industry: e.target.value })
              }
              value={props.form.industry}
            >
              <option>Please select</option>

              <option value='Beauty salon'>Beauty salon</option>
              <option value='Cannabis'>Cannabis</option>
              <option value='Fashion boutique'>Fashion boutique</option>
              <option value='Grocery'>Grocery</option>
              <option value='Hardware'>Hardware</option>
              <option value='Healthcare'>Healthcare</option>
              <option value='Hotel/lodging'>Hotel/lodging</option>
              <option value='Lodging'>Lodging</option>
              <option value='Non-profit'>Non-profit</option>
              <option value='Online/e-commerce'>Online/e-commerce</option>
              <option value='Professional services'>
                Professional services
              </option>
              <option value='Restaurant'>Restaurant</option>
              <option value='Retail'>Retail</option>
              <option value='Travel agency'>Travel agency</option>
              <option value='Wellness'>Wellness</option>
              <option value='Wholesale'>Wholesale</option>
              <option value='Other'>Other</option>
            </select>

            {props.errors.industry && (
              <div className='fv-plugins-message-container'>
                <div
                  data-field='industry'
                  data-validator='notEmpty'
                  className='fv-help-block'
                >
                  {props.errors.industry}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='d-flex pt-15 justify-content-between mb-20'>
          <button
            type='button'
            onClick={() => props.previousPage()}
            className='btn btn-lg btn-light-primary me-3'
          >
            <i className='ki-outline ki-arrow-left fs-4 me-1' />
            Previous
          </button>

          <button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={next}
          >
            Continue
            <i className='ki-outline ki-arrow-right fs-4 ms-1' />
          </button>
        </div>
      </div>
    </>
  );
}
