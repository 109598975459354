import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { toaster } from 'baseui/toast';
import { useEffect, useState } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import Api from '../Api';

export default function CustomerLayout() {
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwrordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState({}) as any;

  useEffect(() => {
    getSession();
  }, []);

  const update = async () => {
    if (!validatePassword(password)) {
      //toaster.negative("Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character")
      setError(passwrordError);
      return;
    } else {
      setError('');
    }

    setLoading(true);

    try {
      await Api.post('auth/update-password', {
        password: password,
        currentPassword: currentPassword
      });

      setIsOpen(false);
      setLoading(false);
      toaster.positive('Password updated successfully');
      setPassword('');
      setCurrentPassword('');
      setNewPassword('');
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      try {
        let { message } = error.response.data;

        if (message) {
          return toaster.negative(message);
        }
      } catch (e) {
        toaster.negative('An error occurred');
      }
    }
  };

  function validatePassword(password: string) {
    var errors = [];

    if (!/(?=.*[a-z])/.test(password)) {
      errors.push('at least one lowercase letter');
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push('at least one uppercase letter');
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push('at least one number');
    }
    if (!/[!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?]+/g.test(password)) {
      errors.push('at least one special character');
    }
    if (password.length < 6) {
      errors.push('at least 6 characters long');
    }

    if (password !== newPassword) {
      errors.push('Passwords do not match.');
    }

    if (errors.length > 0) {
      setPasswordError('Password is missing: ' + errors.join(', '));
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  }

  const logout = async () => {
    try {
      await Api.get('auth/logout');

      localStorage.removeItem('token');

      window.location.href = '/login';
    } catch (error) {
      console.error(error);
    }
  };

  const getSession = async () => {
    try {
      let { data } = await Api.get('auth/me');
      setUser(data);
    } catch (error) {
      console.error(error);
      window.location.href = '/login';
    }
  };

  return (
    <>
      <div id='kt_app_header' className='app-header' data-kt-sticky='true'>
        <div
          className='app-container container-xxl d-flex align-items-stretch justify-content-between'
          id='kt_app_header_container'
        >
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-18'>
            <Link to='/dashboard'>
              <img
                alt='Logo'
                src='/assets/media/logos/custom-1.png'
                className='h-25px'
              />
            </Link>
          </div>

          <div
            className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
            id='kt_app_header_wrapper'
          >
            <div className='app-header-menu app-header-mobile-drawer align-items-stretch'>
              <div
                className='menu menu-rounded menu-active-bg menu-state-primary menu-column menu-lg-row menu-title-gray-700 menu-icon-gray-500 menu-arrow-gray-500 menu-bullet-gray-500 my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0'
                id='kt_app_header_menu'
                data-kt-menu='true'
              >
                <div className='menu-item  me-0 me-lg-2'>
                  <NavLink to='/dashboard' end={true} className='menu-link'>
                    <span className='menu-title'>Dashboard</span>
                    <span className='menu-arrow d-lg-none'></span>
                  </NavLink>
                </div>

                <div className='menu-item  me-0 me-lg-2'>
                  <NavLink to='/customers' className='menu-link'>
                    <span className='menu-title'>Customers</span>
                    <span className='menu-arrow d-lg-none'></span>
                  </NavLink>
                </div>

                <div className='menu-item  me-0 me-lg-2'>
                  <NavLink to='/businesses' className='menu-link'>
                    <span className='menu-title'>Businesses</span>
                    <span className='menu-arrow d-lg-none'></span>
                  </NavLink>
                </div>

                <div className='menu-item  me-0 me-lg-2'>
                  <NavLink to='/merchants' className='menu-link'>
                    <span className='menu-title'>Merchants</span>
                    <span className='menu-arrow d-lg-none'></span>
                  </NavLink>
                </div>

                {/* <div className="menu-item  me-0 me-lg-2">

                                    <NavLink to="/quotes" className="menu-link">
                                        <span className="menu-title">Quotes</span>
                                        <span className="menu-arrow d-lg-none"></span>
                                    </NavLink>

                                </div> */}
              </div>
            </div>

            <div className='app-navbar flex-shrink-0'>
              <div className='app-navbar-item ms-1 ms-lg-5'>{user.name}</div>

              <div className='app-navbar-item ms-1 ms-lg-5'>
                <button
                  className='btn btn-icon btn-custom  w-35px h-35px w-md-40px h-md-40px'
                  onClick={() => setIsOpen(true)}
                >
                  <i className='fa fa-cog fs-1 btn-white'></i>
                </button>
              </div>

              <div className='app-navbar-item ms-1 ms-lg-5'>
                <button
                  className='btn btn-icon btn-custom  w-35px h-35px w-md-40px h-md-40px'
                  onClick={logout}
                >
                  <i className='fa fa-lock fs-1 btn-white'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='app-wrapper flex-column flex-row-fluid'>
        {/** children */}

        <Outlet />

        <div className='app-container container-xxl'>
          <div>
            <a href='https://merchantstacks.com'>Back to main website</a>
            &nbsp; &bull; &nbsp;
            <a href='https://merchantstacks.com/terms-of-service/'>
              Terms of service
            </a>
            &nbsp; &bull; &nbsp;
            <a href='https://merchantstacks.com/privacy-policy/'>
              Privacy policy
            </a>
            &nbsp; &bull; &nbsp;
            <a href='https://merchantstacks.com/contact'>Contact us</a>
            &nbsp; &bull; &nbsp;
            <a href='https://merchantstacks.com/ccpa-form/'>
              {' '}
              Do not sell my information
            </a>
          </div>
        </div>
      </div>
      <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        //size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Update your password</ModalHeader>
        <ModalBody>
          <div>
            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'>Current Password</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='password'
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder=' password'
              />
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'>Update Password</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder=' password'
              />
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'>Confirm Password Update</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder='Confirm password'
              />
            </div>

            {error && (
              <div className='fv-plugins-message-container'>
                <div data-kt-control='input-error'>{passwrordError}</div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={loading} onClick={update}>
            Update Password
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
