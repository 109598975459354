import { useState } from 'react';
import Account from '../components/Account';
import GetStarted from '../components/GetStarted';
import Information from '../components/Information';
import Merchants from '../components/Merchants';
import Payments from '../components/Payments';

export default function Landing() {
  const [currentStep, setCurrentStep] = useState(1);
  const [uuid] = useState('');
  const [form, setForm] = useState({
    merchantServices: 'no',
    volume: '',
    industry: '',
    phone: '',
    email: '',
    name: '',
    taxId: '',
    businessName: '',
    payments: '',
    password: ''
  });

  const [errors, setErrors] = useState({
    phone: false,
    email: false,
    name: false,
    taxId: false,
    businessName: false,
    payments: false,
    password: false
  });

  const nextPage = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousPage = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <div className='d-flex flex-column flex-root' id='kt_app_root'>
        <div
          className='d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep'
          id='kt_create_account_stepper'
          data-kt-stepper='true'
        >
          <div className='d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px'>
            <div className='d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center bg-border-right'>
              {/* <a href="https://merchantstacks.com/" target="_blank">
                                <div className="d-flex flex-center py-10 py-lg-10 mt-lg-20">

                                    <img alt="Logo" src="assets/media/logos/custom-1.png" className="h-70px" />

                                </div>
                            </a> */}

              <div className='d-flex flex-row-fluid justify-content-center p-10 d-none d-sm-block py-10'>
                <div className='stepper-nav mt-sm-10'>
                  <div
                    className={`stepper-item ${
                      currentStep === 1 ? 'current' : ''
                    }`}
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon rounded-3'>
                        <i className='ki-outline ki-check fs-3 stepper-check'></i>
                        <span className='stepper-number'>1</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title fs-3'>Get started</h3>
                        <div className='stepper-desc fw-normal'>
                          About MerchantStacks
                        </div>
                      </div>
                    </div>

                    <div className='stepper-line h-30px'></div>
                  </div>

                  <div
                    className={`stepper-item ${
                      currentStep === 2 ? 'current' : ''
                    }`}
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon rounded-3'>
                        <i className='ki-outline ki-check fs-3 stepper-check'></i>
                        <span className='stepper-number'>2</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title fs-3'>Services</h3>
                        <div className='stepper-desc fw-normal'>
                          Select existing merchant services
                        </div>
                      </div>
                    </div>

                    <div className='stepper-line h-30px'></div>
                  </div>

                  <div
                    className={`stepper-item ${
                      currentStep === 3 ? 'current' : ''
                    }`}
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon'>
                        <i className='ki-outline ki-check fs-3 stepper-check'></i>
                        <span className='stepper-number'>3</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title fs-3'>Payments</h3>
                        <div className='stepper-desc fw-normal'>
                          How will you accept payments
                        </div>
                      </div>
                    </div>

                    <div className='stepper-line h-30px'></div>
                  </div>

                  <div
                    className={`stepper-item ${
                      currentStep === 4 ? 'current' : ''
                    }`}
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon'>
                        <i className='ki-outline ki-check fs-3 stepper-check'></i>
                        <span className='stepper-number'>4</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Business</h3>
                        <div className='stepper-desc fw-normal'>
                          Save your business information
                        </div>
                      </div>
                    </div>

                    <div className='stepper-line h-30px'></div>
                  </div>
                  {/* end::Step 4 */}
                  {/* begin::Step 5 */}
                  <div
                    className={`stepper-item ${
                      currentStep === 5 ? 'current' : ''
                    }`}
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon'>
                        <i className='ki-outline ki-check fs-3 stepper-check'></i>
                        <span className='stepper-number'>5</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Account</h3>
                        <div className='stepper-desc fw-normal'>
                          Your Merchant Stacks account
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end::Step 5 */}
                </div>
                {/* end::Nav */}
              </div>

              {/* begin::Footer *  /}
                            <div className="d-flex flex-center flex-wrap px-5 py-10 d-none d-sm-block">
                                {/ * begin::Links * /}
                                <div className="d-flex fw-normal">
                                    <a href="https://merchantstacks.com/" className="text-success px-5" target="_blank"> Homepage</a>
                                </div>
                                {/* end::Links * /}
                            </div>
                            {/ * end::Footer */}
            </div>
          </div>

          <div className='d-flex flex-column flex-lg-row-fluid py-10'>
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid'>
              <div className='w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto'>
                {currentStep === 1 && (
                  <GetStarted
                    currentStep={currentStep}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}

                {currentStep === 2 && (
                  <Merchants
                    currentStep={currentStep}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    uuid={uuid}
                    form={form}
                    setForm={setForm}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}

                {currentStep === 3 && (
                  <Payments
                    currentStep={currentStep}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    uuid={uuid}
                    form={form}
                    setForm={setForm}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}

                {currentStep === 4 && (
                  <Information
                    currentStep={currentStep}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    uuid={uuid}
                    form={form}
                    setForm={setForm}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}

                {currentStep === 5 && (
                  <Account
                    currentStep={currentStep}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    uuid={uuid}
                    form={form}
                    setForm={setForm}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
