import { useState } from 'react';
import { Link } from 'react-router-dom';
import Account from '../session/Account';
import Information from '../session/Information';
import Merchants from '../session/Merchants';
import Payments from '../session/Payments';

export default function AddBusiness() {
  const [currentStep, setCurrentStep] = useState(1);
  const [uuid] = useState('');
  const [form, setForm] = useState({
    merchantServices: 'no',
    volume: '',
    industry: '',
    phone: '',
    email: '',
    name: '',
    taxId: '',
    businessName: '',
    payments: ''
  });

  const [errors, setErrors] = useState({
    phone: false,
    email: false,
    name: false,
    taxId: false,
    businessName: false,
    payments: false,
    password: false
  });

  const nextPage = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousPage = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <div id='kt_app_toolbar' className='py-6 '>
        <div
          id='kt_app_toolbar_container'
          className='app-container  container-xxl d-flex align-items-start '
        >
          <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/dashboard'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-white fs-3'></i>
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  Create a new business
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container container-xxl'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body p-10'>
            <div
              className='d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep'
              id='kt_create_account_stepper'
              data-kt-stepper='true'
            >
              <div className='d-flex flex-column flex-lg-row-fluid'>
                {/* begin::Content */}
                <div className='d-flex flex-center flex-column flex-column-fluid'>
                  <div className='w-lg-650px w-xl-700px p-10 mx-auto'>
                    {currentStep === 1 && (
                      <Merchants
                        currentStep={currentStep}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        uuid={uuid}
                        form={form}
                        setForm={setForm}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    )}

                    {currentStep === 2 && (
                      <Payments
                        currentStep={currentStep}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        uuid={uuid}
                        form={form}
                        setForm={setForm}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    )}

                    {currentStep === 3 && (
                      <Information
                        currentStep={currentStep}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        uuid={uuid}
                        form={form}
                        setForm={setForm}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    )}

                    {currentStep === 4 && (
                      <Account
                        currentStep={currentStep}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        uuid={uuid}
                        form={form}
                        setForm={setForm}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
