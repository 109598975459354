import { Button, KIND, SIZE } from 'baseui/button';
import { FileUploader } from 'baseui/file-uploader';
import { Pagination } from 'baseui/pagination';
import { toaster } from 'baseui/toast';
import { useEffect, useState } from 'react';
import Api from '../Api';

export default function CustomerDashboard() {
  const [business, setBusiness] = useState({}) as any;
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [quotes, setQuotes] = useState([]) as any;
  const [total] = useState(0);
  const [perPage] = useState(20);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [getting, setGetting] = useState(true);
  const [setMe] = useState({}) as any;
  const [name, setName] = useState('');
  const [volume] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [industry] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [merchant_services] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [sorted, setSorted] = useState({
    business_id: 'asc',
    total_saving: 'asc',
    current_promotion: 'asc',
    machine_cost: 'asc',
    monthly_fee: 'asc',
    interchange_fees: 'asc',
    status: 'asc'
  }) as any;

  useEffect(() => {
    getSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBusinesses();
  }, [merchant_services, industry, volume]);

  const getSession = async () => {
    try {
      const response = await Api.get('auth/me');
      if (response.status === 200) {
        //  setSignedIn(true);
        setMe(response.data);

        let firstName = response.data.name.split(' ')[0];

        setName(firstName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getQuotes = async () => {
    setLoading(true);

    try {
      let { data } = await Api.get('business/quotes?page=' + page);

      setQuotes(data);
      // setTotal(data.meta.total);
      // setPerPage(data.meta.per_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getBusinesses = async () => {
    setGetting(true);
    try {
      let { data } = await Api.get('business');

      setBusiness(data);

      setGetting(false);
    } catch (error) {
      console.error(error);

      setGetting(false);
    }
  };

  const update = async (id: number, status: string) => {
    setUpdating(true);
    try {
      await Api.post('business/update-quote', {
        status: status,
        id: id
      });

      await getBusinesses();
      setUpdating(false);

      //refresh window
      window.location.href = '/dashboard';

      toaster.positive('Quote has been updated successfully');
    } catch (error: any) {
      console.error(error);

      setUpdating(false);

      try {
        let { message } = error.response.data;

        if (message) {
          return toaster.negative(message);
        }
      } catch (e) {
        toaster.negative('An error occurred');
      }
    }
  };

  //data sort asc or desc
  const sort = (field: string) => {
    let sort = 'asc';

    //check if field sort is already set
    if (sorted[field] === 'asc') {
      sort = 'desc';
    }

    //save field state to switch between asc and desc
    setSorted({ ...sorted, [field]: sort });

    let qq = quotes.sort((a: any, b: any) => {
      if (sort === 'asc') {
        return a[field] - b[field];
      } else {
        return b[field] - a[field];
      }
    });

    setQuotes([...qq]);
  };

  const renderIcon = (name: string) => {
    let icon = sorted[name] === 'asc' ? 'up' : 'down';

    let iconClass = 'bi bi-arrow-' + icon + ' fs-7 ms-1';
    return <i className={iconClass}></i>;
  };

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar py-6'>
        <div
          id='kt_app_toolbar_container'
          className='app-container container-xxl d-flex align-items-start'
        >
          <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <a
                    href='index.html'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-gray-700 fs-6'></i>
                  </a>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-7 text-gray-700 mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  Dashboard
                </li>
              </ul>
            </div>

            <div className='d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-13 pb-6'>
              <div className='page-title me-5'>
                <h1 className='page-heading d-flex text-white fw-bold fs-2 flex-column justify-content-center my-0'>
                  Welcome, {name} <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container container-xxl'>
        <div className='app-content '>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body p-10'>
              <div className=' flex-column'>
                {!getting && (
                  <div className='row gx-5 gx-xl-10'>
                    {business && !business?.statement && (
                      <div className=' mb-10 d-flex w-100 flex-center'>
                        <div className='card card-flush w-400px carousel carousel-custom carousel-stretch slide h-xl-100'>
                          <div className='card-header pt-5'>
                            <h4 className='card-title d-flex align-items-start flex-column'>
                              <span className='card-label fw-bold text-gray-800'>
                                Add your business statement for:{' '}
                                {business.business_name}
                              </span>
                              <span className='text-gray-500 mt-1 fw-bold fs-7'>
                                Please provide your merchant statement, bank
                                statement, or any relevant financial document,
                                ideally in PDF format, covering the past two
                                months.
                              </span>
                            </h4>
                          </div>

                          <div className='card-body  py-5'>
                            <div className='d-flex flex-column flex-center py-10'>
                              <FileUploader
                                accept='.pdf,.doc,.docx,.csv,.txt,.rtf,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp,.odg,.odf'
                                errorMessage={errorMessage}
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                  setIsUploading(true);
                                  let formData = new FormData();
                                  formData.append(
                                    'statement',
                                    acceptedFiles[0]
                                  );
                                  Api.post(
                                    'business/statement/' + business.id,
                                    formData
                                  )
                                    .then((response) => {
                                      setIsUploading(false);
                                      window.location.href = '/dashboard';
                                    })
                                    .catch((error) => {
                                      console.error(error);
                                      setIsUploading(false);
                                      setErrorMessage('Error uploading file');
                                    });
                                }}
                                progressMessage={
                                  isUploading ? 'Uploading...' : ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {getting && (
                  <div className='row gx-5 gx-xl-10 text-center'>
                    Please wait...
                  </div>
                )}

                <div className='dt-container dt-bootstrap5 dt-empty-footer'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable'>
                      <thead>
                        <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px'>
                            <span
                              className='dt-column-title cursor'
                              onClick={() => sort('business_id')}
                            >
                              Business Name
                              {renderIcon('business_id')}
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Merchant Name
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span
                              className='dt-column-title cursor'
                              onClick={() => sort('total_saving')}
                            >
                              Total savings
                              {renderIcon('total_saving')}
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span
                              className='dt-column-title cursor'
                              onClick={() => sort('current_promotion')}
                            >
                              Current Promotion
                              {renderIcon('current_promotion')}
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span
                              className='dt-column-title cursor'
                              onClick={() => sort('machine_cost')}
                            >
                              Machine Cost
                              {renderIcon('machine_cost')}
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span
                              className='dt-column-title cursor'
                              onClick={() => sort('monthly_fee')}
                            >
                              Monthly Fee
                              {renderIcon('monthly_fee')}
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span
                              className='dt-column-title cursor'
                              onClick={() => sort('interchange_fees')}
                            >
                              Interchange Fees
                              {renderIcon('interchange_fees')}
                            </span>
                          </th>

                          <th className='min-w-100px'>
                            <span
                              className='dt-column-title cursor'
                              onClick={() => sort('status')}
                            >
                              Status
                              {renderIcon('status')}
                            </span>
                          </th>

                          <th className='min-w-70px dt-orderable-none'>
                            <span className='dt-column-title'>Actions</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {loading && (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              Loading...
                            </td>
                          </tr>
                        )}
                        {!loading && quotes.length === 0 && (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              No Quotes yet. Check back later
                            </td>
                          </tr>
                        )}

                        {quotes.map((merchant: any) => (
                          <tr key={merchant?.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.business?.business_name}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.user.merchant_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.total_saving}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.current_promotion}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.machine_cost}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.monthly_fee}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.interchange_fees}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.status.toUpperCase()}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-space-between flex-row'>
                                  {merchant.status === 'pending' && (
                                    <>
                                      <Button
                                        size={SIZE.mini}
                                        onClick={() => {
                                          update(merchant.id, 'accepted');
                                        }}
                                        isLoading={updating}
                                      >
                                        Accept
                                      </Button>

                                      <div className='w-10px' />

                                      <Button
                                        size={SIZE.mini}
                                        onClick={() => {
                                          update(merchant.id, 'rejected');
                                        }}
                                        kind={KIND.tertiary}
                                        isLoading={updating}
                                      >
                                        Reject
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {Math.ceil(total / perPage) > 1 && (
              <div className='card-footer py-6'>
                <div className='d-flex align-items-center flex-wrap'>
                  <div className='d-flex align-items-center gap-3 w-200px'>
                    <span className='text-muted fs-7 fw-bold'>
                      Showing {quotes.length} out of {total} quotes
                    </span>
                  </div>

                  <div className='d-flex align-items-center gap-3 w-500px'>
                    <Pagination
                      numPages={Math.ceil(total / perPage)}
                      currentPage={page}
                      onPageChange={({ nextPage }) => setPage(nextPage)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
