import { Button } from 'baseui/button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import Api from '../Api';

export default function Account(props: any) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [passwrordError, setPasswordError] = useState('');

  const complete = async () => {
    if (props.form.password !== '')
      if (!validatePassword(props.form.password)) {
        //toaster.negative("Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character")
        props.setErrors({
          password: passwrordError
        });
        console.error('bad pass');
        return;
      } else {
        props.setErrors({
          password: false
        });
      }

    setLoading(true);

    try {
      let { data } = await Api.post('auth/register-business', props.form);

      //save token
      localStorage.setItem('token', data.token);

      //redirect to dashboard
      //props.history.push("/dashboard")

      // toaster.positive("Account created successfully. You can check your email for more details.")

      setLoading(false);

      window.location.href = '/dashboard';
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      const { message } = error.response.data;

      if (message) {
        toaster.negative(message);
      }
    }
  };

  function validatePassword(password: string) {
    var errors = [];

    if (!/(?=.*[a-z])/.test(password)) {
      errors.push('at least one lowercase letter');
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push('at least one uppercase letter');
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push('at least one number');
    }
    if (!/[!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?]+/g.test(password)) {
      errors.push('at least one special character');
    }
    if (password.length < 6) {
      errors.push('at least 6 characters long');
    }

    if (errors.length > 0) {
      setPasswordError('Password is missing: ' + errors.join(', '));
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  }

  return (
    <>
      <div className='current flex-column' data-kt-stepper-element='content'>
        <div className='w-100'>
          <a
            href='https://merchantstacks.com'
            target='new'
            className='d-flex w-100 mb-10'
          >
            <img
              alt='Logo'
              src='assets/media/logos/custom-1.png'
              className='h-50px'
            />
          </a>

          <div className='pb-8 pb-lg-10'>
            <h2 className='fw-bold text-gray-900'>Your are almost done!</h2>

            <div className='text-gray-800 fw-semibold fs-6 mb-2'>
              Please enter your password to complete the registration. You will
              be able to upload required documents and see available quotes. Or
              skip and get login details emailed to you.
            </div>

            <div className='text-gray-800 fw-semibold fs-6'>
              You will login using the email address:{' '}
              <strong>{props.form.email}</strong>
            </div>
          </div>

          <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label'>Password</label>

            <input
              className='form-control form-control-lg form-control-solid'
              type='password'
              placeholder='Enter your password'
              value={props.form.password}
              onChange={(e) =>
                props.setForm({ ...props.form, password: e.target.value })
              }
            />

            {props.errors.password && (
              <div className='fv-plugins-message-container'>
                <div data-kt-control='input-error' className='fv-help-block'>
                  {props.errors.password}
                </div>
              </div>
            )}
          </div>

          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            I agree to the{' '}
            <a
              href='https://merchantstacks.com/terms-of-service'
              target='new'
              className='fw-bolder'
            >
              terms of service
            </a>
          </Checkbox>
        </div>

        <div className='d-flex pt-15 justify-content-between mb-20'>
          <button
            type='button'
            onClick={() => props.previousPage()}
            className='btn btn-lg btn-light-primary me-3'
          >
            <i className='ki-outline ki-arrow-left fs-4 me-1' />
            Previous
          </button>

          <Button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={complete}
            isLoading={loading}
            disabled={!checked}
          >
            Submit
            <i className='ki-outline ki-arrow-right fs-4 ms-1' />
          </Button>
        </div>
      </div>
    </>
  );
}
