//import { toaster } from "baseui/toast"
import { Select } from 'baseui/select';
import { useEffect, useState } from 'react';

export default function Payments(props: any) {
  const [value, setValue] = useState([]) as any;

  useEffect(() => {
    let str = '';

    for (let index = 0; index < value.length; index++) {
      const e = value[index];

      str += e.label + ', ';
    }

    //remove trailing comma
    str = str.slice(0, -2);

    props.setForm({ ...props.form, payments: str });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const next = () => {
    //check if fields are filled
    if (props.form.payments === '' || props.form.volume === '') {
      // toaster.negative("Please fill all fields")
      props.setErrors({
        payments: props.form.payments === '' ? 'This field is required' : '',
        volume: props.form.volume === '' ? 'This field is required' : ''
      });
      return;
    } else {
      props.setErrors({
        payments: false,
        volume: false
      });
    }

    props.nextPage();
  };

  return (
    <>
      <div className='current flex-column' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
            <label className='d-flex align-items-center form-label mb-5'>
              How do you accept payments?
            </label>

            <div className='mb-10'>
              <Select
                options={[
                  { label: 'In person', id: 1 },
                  { label: 'Online', id: 2 },
                  { label: 'Over the phone', id: 3 }
                ]}
                value={value}
                multi
                placeholder='Select options'
                onChange={(params) => setValue(params.value)}
              />

              {/* <label className="d-flex flex-stack mb-5 cursor-pointer">

                                <span className="d-flex align-items-center me-2">

                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label">
                                            <i className="ki-outline ki-chart fs-1 text-gray-600"></i>
                                        </span>
                                    </span>


                                    <span className="d-flex flex-column">
                                        <span className="fw-bold text-gray-800 text-hover-primary fs-5">In person</span>
                                    </span>

                                </span>


                                <span className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="option-input"
                                        type="radio"
                                        checked={props.form?.payments === "In person" ? true : false}
                                        name="payments"
                                        value="yes"
                                        onChange={() => props.setForm({ ...props.form, payments: "In person" })}
                                    />
                                </span>

                            </label>

                            <label className="d-flex flex-stack mb-5 cursor-pointer">

                                <span className="d-flex align-items-center me-2">

                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label">
                                            <i className="ki-outline ki-chart fs-1 text-gray-600"></i>
                                        </span>
                                    </span>


                                    <span className="d-flex flex-column">
                                        <span className="fw-bold text-gray-800 text-hover-primary fs-5">Online</span>
                                    </span>

                                </span>


                                <span className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="option-input"
                                        type="radio"
                                        checked={props.form?.payments === "Online" ? true : false}
                                        name="payments"
                                        value="yes"
                                        onChange={() => props.setForm({ ...props.form, payments: "Online" })}
                                    />
                                </span>

                            </label>

                            <label className="d-flex flex-stack mb-5 cursor-pointer">

                                <span className="d-flex align-items-center me-2">

                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label">
                                            <i className="ki-outline ki-chart fs-1 text-gray-600"></i>
                                        </span>
                                    </span>


                                    <span className="d-flex flex-column">
                                        <span className="fw-bold text-gray-800 text-hover-primary fs-5">Over the phone</span>
                                    </span>

                                </span>


                                <span className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="option-input"
                                        type="radio"
                                        checked={props.form?.payments === "Over the phone" ? true : false}
                                        name="payments"
                                        value="yes"
                                        onChange={() => props.setForm({ ...props.form, payments: "Over the phone" })}
                                    />
                                </span>

                            </label> */}

              {props.errors.payments && (
                <div className='fv-plugins-message-container'>
                  <div
                    data-field='email'
                    data-validator='notEmpty'
                    className='fv-help-block'
                  >
                    {props.errors.payments}
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
              <label className='d-flex align-items-center form-label mb-3'>
                What's your monthly volume?
              </label>

              <div
                className='row mb-2'
                data-kt-buttons='true'
                data-kt-initialized='1'
              >
                <div className='col'>
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                      props.form.volume === '<10K' ? 'active' : ''
                    }`}
                  >
                    <input
                      type='radio'
                      className='btn-check'
                      name='volume'
                      value='<10K'
                      onChange={() =>
                        props.setForm({ ...props.form, volume: '<10K' })
                      }
                    />
                    <span className='fw-bold fs-3'> 1k - 10K </span>
                  </label>
                </div>

                <div className='col'>
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                      props.form.volume === '10k-50k' ? 'active' : ''
                    }`}
                  >
                    <input
                      type='radio'
                      className='btn-check'
                      name='volume'
                      value='10k-50k'
                      onChange={() =>
                        props.setForm({ ...props.form, volume: '10k-50k' })
                      }
                    />
                    <span className='fw-bold fs-3'> 10K - 50K </span>
                  </label>
                </div>

                <div className='col'>
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                      props.form.volume === '50k-100k' ? 'active' : ''
                    }`}
                  >
                    <input
                      type='radio'
                      className='btn-check'
                      name='volume'
                      value='50k-100k'
                      onChange={() =>
                        props.setForm({ ...props.form, volume: '50k-100k' })
                      }
                    />
                    <span className='fw-bold fs-3'>50K - 100K </span>
                  </label>
                </div>

                <div className='col'>
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                      props.form.volume === '100K+' ? 'active' : ''
                    }`}
                  >
                    <input
                      type='radio'
                      className='btn-check'
                      name='volume'
                      value='100K+'
                      onChange={() =>
                        props.setForm({ ...props.form, volume: '100K+' })
                      }
                    />
                    <span className='fw-bold fs-3'> 100K+ </span>
                  </label>
                </div>

                {/* <div className="col">

                                    <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${props.form.volume === "100K+" ? "active" : ""}`}>
                                        <input type="radio"
                                            className="btn-check" name="volume" value="100+"
                                            onChange={() => props.setForm({ ...props.form, volume: "100K+" })}
                                        />
                                        <span className="fw-bold fs-3">100K &gt;</span>
                                    </label>

                                </div> */}
              </div>
              {props.errors.volume && (
                <div className='fv-plugins-message-container'>
                  <div
                    data-field='email'
                    data-validator='notEmpty'
                    className='fv-help-block'
                  >
                    {props.errors.volume}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex pt-5 justify-content-between mb-20'>
          <button
            type='button'
            onClick={() => props.previousPage()}
            className='btn btn-lg btn-light-primary me-3'
          >
            <i className='ki-outline ki-arrow-left fs-4 me-1' />
            Previous
          </button>

          <button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={next}
          >
            Continue
            <i className='ki-outline ki-arrow-right fs-4 ms-1' />
          </button>
        </div>
      </div>
    </>
  );
}
