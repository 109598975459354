import { Button, KIND, SIZE } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { FileUploader } from 'baseui/file-uploader';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SIZE as ModalSize,
  ROLE
} from 'baseui/modal';
import { Pagination } from 'baseui/pagination';
import { StatefulPopover } from 'baseui/popover';
import { Select } from 'baseui/select';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';
var ssnRegex = /^\d{9}$/;
var einRegex = /^\d{2}-\d{7}$/;

export default function Businesses() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [biz, setBiz] = useState([]) as any;
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null) as any;
  const [form, setForm] = useState({
    phone: '',
    email: '',
    name: '',
    taxId: '',
    businessName: '',
    payments: '',
    password: '',
    merchantServices: '',
    industry: '',
    volume: ''
  }) as any;
  const [errors, setErrors] = useState({
    phone: false,
    email: false,
    taxId: false,
    businessName: false,
    payments: false,
    password: false,
    merchantServices: false,
    industry: false,
    volume: false
  }) as any;
  const [value, setValue] = useState([]) as any;
  const [statement, setStatement] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    getBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    //fill form values from selected.business
    if (selected) {
      setForm({
        phone: selected.business.phone,
        email: selected.business.email,
        taxId: selected.business.tax_id,
        businessName: selected.business.business_name,
        payments: selected.business.payments,
        password: selected.business.password,
        merchantServices: selected.business.merchant_services,
        industry: selected.business.industry,
        volume: selected.business.volume
      });
    }

    //set value array
    if (selected) {
      let arr = selected.business.payments.split(',').map((e: any) => {
        return { label: e, id: e };
      });

      setValue(arr);
    }
  }, [selected]);

  useEffect(() => {
    let str = '';

    for (let index = 0; index < value.length; index++) {
      const e = value[index];

      str += e.label + ', ';
    }

    //remove trailing comma
    str = str.slice(0, -2);

    setForm({ ...form, payments: str });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getBusinesses = async () => {
    try {
      let { data } = await Api.get('business/businesses?page=' + page);

      setPerPage(data.meta.per_page);
      setTotal(data.meta.total);
      setBiz(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
    }
  };

  const save = async () => {
    if (loading) return;

    //check if fields are filled
    if (form.phone === '' || form.email === '' || form.businessName === '') {
      //   toaster.negative("Please fill all fields")
      setErrors({
        phone: form.phone === '' ? 'This field is required' : '',
        email: form.email === '' ? 'This field is required' : '',
        businessName: form.businessName === '' ? 'This field is required' : ''
      });
      return;
    } else {
      setErrors({
        phone: false,
        email: false,
        businessName: false
      });
    }

    //regex to test email address
    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(form.email)) {
      // toaster.negative("Please enter a valid email address")
      setErrors({
        email: 'Please enter a valid email address'
      });
      return;
    } else {
      setErrors({
        email: false
      });
    }

    //regex to test usa phone number
    const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    if (!phoneRegex.test(form.phone)) {
      //  toaster.negative("Please enter a valid phone number")
      setErrors({
        phone: 'Please enter a valid phone number'
      });
      return;
    } else {
      setErrors({
        phone: false
      });
    }

    if (form.taxId !== '')
      if (!validateTaxID(form.taxId)) {
        //   toaster.negative("Please enter a valid tax id")
        setErrors({
          taxId: 'Please enter a valid tax id'
        });
        return;
      } else {
        setErrors({
          taxId: false
        });
      }

    //check if fields are filled
    if (form.merchantServices === '' || form.industry === '') {
      //   toaster.negative("Please fill all fields")
      setErrors({
        merchantServices:
          form.merchantServices === '' ? 'This field is required' : '',
        industry: form.industry === '' ? 'This field is required' : ''
      });
      return;
    } else {
      setErrors({
        merchantServices: false,
        industry: false
      });
    }

    if (form.payments === '' || form.volume === '') {
      // toaster.negative("Please fill all fields")
      setErrors({
        payments: form.payments === '' ? 'This field is required' : '',
        volume: form.volume === '' ? 'This field is required' : ''
      });
      return;
    } else {
      setErrors({
        payments: false,
        volume: false
      });
    }

    setLoading(true);

    try {
      await Api.put('business/' + selected.business.id, form);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setShow(false);
      getBusinesses();
    }
  };

  const destroy = async (bis: any) => {
    if (loading) return;

    setLoading(true);

    try {
      await Api.delete('business/' + bis.id);

      await getBusinesses();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const validateTaxID = (taxID: any) => {
    if (ssnRegex.test(taxID)) {
      return true;
    } else if (einRegex.test(taxID)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar  py-6 '>
        <div
          id='kt_app_toolbar_container'
          className='app-container  container-xxl d-flex align-items-start '
        >
          <div className='d-flex flex-row  flex-row-fluid justify-between'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/dashboard'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-white fs-3'></i>
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  My Businesses
                </li>
              </ul>
            </div>
            <div className='d-flex gap-4 gap-lg-13 ml-auto'>
              <Link
                to='/add-business'
                className='btn btn-flex btn-sm btn-outline btn-active-color-primary btn-custom px-4'
              >
                <i className='ki-outline ki-plus-square fs-4 me-2'></i>
                Add Business
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container  container-xxl '>
        <div className='app-main flex-column flex-row-fluid '>
          <div className='d-flex flex-column flex-column-fluid'>
            <div className='app-content '>
              <div className='card mb-5 mb-xl-10'>
                {/* <div className="card-header border-0 pt-6">

                                    <div className="card-title">

                                        <div className="d-flex align-items-center position-relative my-1">
                                            <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
                                            <input type="text" className="form-control form-control-solid w-250px ps-12" placeholder="Search Merchants" />
                                        </div>

                                    </div>

                                </div> */}

                <div className='card-body p-10'>
                  <div className='dt-container dt-bootstrap5 dt-empty-footer'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable'>
                        <thead>
                          <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Business Name
                              </span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Industry</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Volume</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Existing Merchant Services
                              </span>
                            </th>
                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Payment Accepted
                              </span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Phone</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Statement</span>
                            </th>

                            <th className='text-end min-w-70px dt-orderable-none'>
                              <span className='dt-column-title'>Actions</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {loading && (
                            <tr>
                              <td colSpan={7} className='text-center'>
                                Loading...
                              </td>
                            </tr>
                          )}
                          {!loading && biz.length === 0 && (
                            <tr>
                              <td colSpan={7} className='text-center'>
                                No Businesses Found
                              </td>
                            </tr>
                          )}

                          {biz.map((merchant: any) => (
                            <tr key={merchant.business.id}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business.business_name}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business.industry}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business.volume}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business.merchant_services.toUpperCase()}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business.payments}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business.phone}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center justify-content-start'>
                                  <div className='d-flex flex-row flex-center'>
                                    {merchant?.business?.statement?.url && (
                                      <a
                                        href={`${merchant?.business?.statement?.url}`}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <i className='bi bi-download fs-3'></i>
                                      </a>
                                    )}

                                    <Button
                                      size={SIZE.mini}
                                      isLoading={loading}
                                      kind={KIND.tertiary}
                                      onClick={() => {
                                        setSelected(merchant);
                                        setStatement(true);
                                      }}
                                    >
                                      <i className='bi bi-upload fs-3'></i>
                                    </Button>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <div className='d-flex justify-between flex-row'>
                                    <ButtonGroup
                                      size={SIZE.mini}
                                      kind={KIND.tertiary}
                                    >
                                      <Button
                                        size={SIZE.mini}
                                        onClick={() => {
                                          setSelected(merchant);
                                          setShow(true);
                                        }}
                                        isLoading={loading}
                                      >
                                        <i className='bi bi-pen fs-3'></i>
                                      </Button>

                                      <StatefulPopover
                                        content={() => (
                                          <div className='flex flex-column flex-center w-150px  text-center p-3'>
                                            <h6 className='text-center py-3'>
                                              Are you sure you want to delete{' '}
                                              {merchant?.business.business_name}
                                            </h6>
                                            <Button
                                              onClick={() => destroy(merchant)}
                                              size={SIZE.mini}
                                              isLoading={loading}
                                            >
                                              Delete
                                            </Button>
                                          </div>
                                        )}
                                        focusLock
                                        returnFocus
                                        renderAll
                                        autoFocus
                                      >
                                        <Button
                                          size={SIZE.mini}
                                          isLoading={loading}
                                          kind={KIND.tertiary}
                                        >
                                          <i className='bi bi-trash fs-3'></i>
                                        </Button>
                                      </StatefulPopover>
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='card-footer py-6'>
                  {Math.ceil(total / perPage) > 1 && (
                    <div className='d-flex align-items-center flex-wrap'>
                      <div className='d-flex align-items-center gap-3 w-200px'>
                        <span className='text-muted fs-7 fw-bold'>
                          Showing {biz.length} out of {total} entries
                        </span>
                      </div>

                      <div className='d-flex align-items-center gap-3 w-500px'>
                        <Pagination
                          numPages={Math.ceil(total / perPage)}
                          currentPage={page}
                          onPageChange={({ nextPage }) => setPage(nextPage)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        onClose={() => setShow(false)}
        closeable
        isOpen={show}
        animate
        autoFocus
        //size={SIZE.default}
        role={ROLE.dialog}
        size={ModalSize.auto}
      >
        <ModalHeader>Edit {selected?.business?.business_name}</ModalHeader>
        <ModalBody>
          <div className='min-w-500px w-100'>
            <div className='mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
              <label className='d-flex align-items-center form-label mb-5'>
                Do you use merchant services?
              </label>

              <div className='mb-0'>
                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label'>
                        <i className='ki-outline ki-chart fs-1 text-gray-600'></i>
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bold text-gray-800 text-hover-primary fs-5'>
                        Yes
                      </span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='option-input'
                      type='radio'
                      checked={form?.merchantServices === 'yes' ? true : false}
                      name='account_plan'
                      value='yes'
                      onChange={() =>
                        setForm({ ...form, merchantServices: 'yes' })
                      }
                    />
                  </span>
                </label>

                <label className='d-flex flex-stack mb-0 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label'>
                        <i className='ki-outline ki-chart-pie-4 fs-1 text-gray-600'></i>
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bold text-gray-800 text-hover-primary fs-5'>
                        No
                      </span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='option-input'
                      type='radio'
                      name='account_plan'
                      checked={form?.merchantServices === 'no' ? true : false}
                      value='no'
                      onChange={() =>
                        setForm({ ...form, merchantServices: 'no' })
                      }
                    />
                  </span>
                </label>
              </div>

              {errors.merchantServices && (
                <div className='fv-plugins-message-container'>
                  <div
                    data-field='merchantServices'
                    data-validator='notEmpty'
                    className='fv-help-block'
                  >
                    {errors.merchantServices}
                  </div>
                </div>
              )}
            </div>

            <div
              className='fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'
              data-select2-id='select2-data-11-mejc'
            >
              <label className='form-label required'>
                What is your industry?
              </label>

              <select
                name='industry'
                className='form-select form-select-lg form-select-solid'
                onChange={(e) => setForm({ ...form, industry: e.target.value })}
                value={form.industry}
              >
                <option>Please select</option>

                <option value='Beauty salon'>Beauty salon</option>
                <option value='Cannabis'>Cannabis</option>
                <option value='Fashion boutique'>Fashion boutique</option>
                <option value='Grocery'>Grocery</option>
                <option value='Hardware'>Hardware</option>
                <option value='Healthcare'>Healthcare</option>
                <option value='Hotel/lodging'>Hotel/lodging</option>
                <option value='Lodging'>Lodging</option>
                <option value='Non-profit'>Non-profit</option>
                <option value='Online/e-commerce'>Online/e-commerce</option>
                <option value='Professional services'>
                  Professional services
                </option>
                <option value='Restaurant'>Restaurant</option>
                <option value='Retail'>Retail</option>
                <option value='Travel agency'>Travel agency</option>
                <option value='Wellness'>Wellness</option>
                <option value='Wholesale'>Wholesale</option>
                <option value='Other'>Other</option>
              </select>

              {errors.industry && (
                <div className='fv-plugins-message-container'>
                  <div
                    data-field='industry'
                    data-validator='notEmpty'
                    className='fv-help-block'
                  >
                    {errors.industry}
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
              <label className='d-flex align-items-center form-label mb-5'>
                How do you accept payments?
              </label>

              <div className='mb-10'>
                <Select
                  options={[
                    { label: 'In person', id: 1 },
                    { label: 'Online', id: 2 },
                    { label: 'Over the phone', id: 3 }
                  ]}
                  value={value}
                  multi
                  placeholder='Select options'
                  onChange={(params) => setValue(params.value)}
                />

                {errors.payments && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='email'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.payments}
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
                <label className='d-flex align-items-center form-label mb-3'>
                  What's your monthly volume?
                </label>

                <div
                  className='row mb-2'
                  data-kt-buttons='true'
                  data-kt-initialized='1'
                >
                  <div className='col'>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                        form.volume === '<10K' ? 'active' : ''
                      }`}
                    >
                      <input
                        type='radio'
                        className='btn-check'
                        name='volume'
                        value='<10K'
                        onChange={() => setForm({ ...form, volume: '<10K' })}
                      />
                      <span className='fw-bold fs-3'> 1k - 10K </span>
                    </label>
                  </div>

                  <div className='col'>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                        form.volume === '10k-50k' ? 'active' : ''
                      }`}
                    >
                      <input
                        type='radio'
                        className='btn-check'
                        name='volume'
                        value='10k-50k'
                        onChange={() => setForm({ ...form, volume: '10k-50k' })}
                      />
                      <span className='fw-bold fs-3'>10K - 50K </span>
                    </label>
                  </div>

                  <div className='col'>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                        form.volume === '50k-100k' ? 'active' : ''
                      }`}
                    >
                      <input
                        type='radio'
                        className='btn-check'
                        name='volume'
                        value='50k-100k'
                        onChange={() =>
                          setForm({ ...form, volume: '50k-100k' })
                        }
                      />
                      <span className='fw-bold fs-3'>50K - 100K </span>
                    </label>
                  </div>

                  <div className='col'>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${
                        form.volume === '<100K' ? 'active' : ''
                      }`}
                    >
                      <input
                        type='radio'
                        className='btn-check'
                        name='volume'
                        value='100K+'
                        onChange={() => setForm({ ...form, volume: '100K+' })}
                      />
                      <span className='fw-bold fs-3'> 100K+ </span>
                    </label>
                  </div>
                </div>
                {errors.volume && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='email'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.volume}
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-10 fv-plugins-icon-container'>
                <label className='form-label required'>Business Name</label>

                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  placeholder='Enter your business name'
                  value={form.businessName}
                  onChange={(e) =>
                    setForm({ ...form, businessName: e.target.value })
                  }
                />

                {errors.businessName && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='businessName'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.businessName}
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-10 fv-plugins-icon-container'>
                <label className='form-label'>Tax ID</label>

                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  placeholder='12-3456789'
                  value={form.taxId}
                  onChange={(e) => setForm({ ...form, taxId: e.target.value })}
                />

                {errors.taxId && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='taxId'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.taxId}
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-10 fv-plugins-icon-container'>
                <label className='form-label required'>
                  Business Phone number
                </label>

                <input
                  className='form-control form-control-lg form-control-solid'
                  type='tel'
                  placeholder='888 888 888'
                  value={form.phone}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                />

                {errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='phone'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.phone}
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-10 fv-plugins-icon-container'>
                <label className='form-label required'>
                  Business Email Address
                </label>

                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  placeholder='John@business.com'
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />

                {errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='email'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.email}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={loading} onClick={save} size={SIZE.compact}>
            Update {selected?.business?.business_name}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        onClose={() => setStatement(false)}
        closeable
        isOpen={statement}
        animate
        autoFocus
        //size={SIZE.default}
        role={ROLE.dialog}
        size={ModalSize.default}
      >
        <ModalHeader>
          Update Statement for {selected?.business?.business_name}
        </ModalHeader>
        <ModalBody>
          <div className='d-flex flex-column flex-center pb-10'>
            <span className='text-gray-500 mb-5 fw-bold fs-7'>
              Please provide your merchant statement, bank statement, or any
              relevant financial document, ideally in PDF format, covering the
              past two months.
            </span>

            <FileUploader
              accept='.pdf,.doc,.docx,.csv,.txt,.rtf,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp,.odg,.odf'
              errorMessage={errorMessage}
              onDrop={(acceptedFiles, rejectedFiles) => {
                setIsUploading(true);
                let formData = new FormData();
                formData.append('statement', acceptedFiles[0]);
                Api.post('business/statement/' + selected.business.id, formData)
                  .then((response) => {
                    setIsUploading(false);
                    getBusinesses();
                    setStatement(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setIsUploading(false);
                    setErrorMessage('Error uploading file');
                  });
              }}
              progressMessage={isUploading ? 'Uploading...' : ''}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
