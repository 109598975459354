import { Link } from 'react-router-dom';

export default function GetStarted(props: any) {
  return (
    <>
      <div className='current' data-kt-stepper-element='content'>
        <div className='w-100'>
          <a
            href='https://merchantstacks.com'
            target='new'
            className='d-flex w-100 flex-center py-10'
          >
            <img
              alt='Logo'
              src='assets/media/logos/custom-1.png'
              className='h-50px'
            />
          </a>

          <div className='pb-10 pb-lg-15 d-flex flex-center flex-column'>
            {/* begin::Title */}
            {/* <h2 className="fw-bold d-flex align-items-center text-gray-900">
                            Merchant Stacks
                        </h2> */}
            <div className='text-muted fw-semibold fs-6 text-center'>
              Merchant Stacks helps customers to compare different pricing and
              features between merchant companies.
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex pt-15 justify-content-between mb-20'>
        <Link to='/login' className='btn btn-lg btn-light-primary me-3'>
          Have an account? Login
        </Link>

        <button
          className='btn btn-lg btn-primary'
          onClick={() => props.nextPage()}
        >
          Get started
          <i className='ki-outline ki-arrow-right fs-4 ms-1' />
        </button>
      </div>
    </>
  );
}
