import { Button, SIZE } from 'baseui/button';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  SIZE as ModalSize,
  ROLE
} from 'baseui/modal';
import { Pagination } from 'baseui/pagination';
import { Select } from 'baseui/select';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';

export default function Customers() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [merchants, setMerchants] = useState([]) as any;
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [volume, setVolume] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [industry, setIndustry] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [merchant_services, setMerchantServices] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [accepts, setAccepts] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null as any);
  const [quotes, setQuotes] = useState([]) as any;

  useEffect(() => {
    getMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, volume, industry, merchant_services, accepts]);

  useEffect(() => {
    if (!selected) return;
    getQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const getQuotes = async () => {
    if (loading) return;

    setLoading(true);

    try {
      let { data } = await Api.get(`admin/business/${selected.id}/quotes`);

      setQuotes(data);

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const getMerchants = async () => {
    if (loading) return;

    setLoading(true);

    try {
      let { data } = await Api.get(
        `admin/businesses?page=${page}&volume=${volume[0]?.id}&industry=${industry[0]?.id}&merchantServices=${merchant_services[0]?.id}&accepts=${accepts[0]?.id}`
      );

      setMerchants(data.data);
      setTotal(data.meta.total);
      setPerPage(data.meta.per_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const destroy = async (id: number) => {
    if (loading) return;

    setLoading(true);

    try {
      await Api.delete(`admin/business/${id}`);

      getMerchants();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const download = async () => {
    if (loading) return;

    setLoading(true);

    try {
      let response = await Api.get('admin/customers-download', {
        responseType: 'blob'
      });

      // Create a Blob from the data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element for downloading
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Customers.csv'; // File name
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const downloadBiz = async () => {
    if (loading) return;

    setLoading(true);

    try {
      let response = await Api.get('admin/businesses-download', {
        responseType: 'blob'
      });

      // Create a Blob from the data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element for downloading
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Businesses.csv'; // File name
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const downloadQuotes = async () => {
    if (loading) return;

    setLoading(true);

    try {
      let response = await Api.get('admin/quotes-download', {
        responseType: 'blob'
      });

      // Create a Blob from the data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element for downloading
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Quotes.csv'; // File name
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar  py-6 '>
        <div
          id='kt_app_toolbar_container'
          className='app-container  container-xxl d-flex align-items-start '
        >
          <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/dashboard'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-white fs-3'></i>
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  Businesses
                </li>
              </ul>
            </div>

            <div className='d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-6 pb-18 py-lg-13'>
              <div className='page-title d-flex align-items-center me-3'>
                <img
                  alt='Logo'
                  src='/assets/media/layer.svg'
                  className='h-60px me-5'
                />

                <h1 className='page-heading d-flex text-white fw-bolder fs-2 flex-column justify-content-center my-0'>
                  Business
                  <span className='page-desc text-white opacity-50 fs-6 fw-bold pt-4'>
                    Business Management
                  </span>
                </h1>
              </div>

              <div className='d-flex gap-3'>
                <Button size={SIZE.mini} onClick={download} isLoading={loading}>
                  <i className='bi bi-download fs-3'></i> &nbsp; Customers
                </Button>

                <Button
                  size={SIZE.mini}
                  onClick={downloadBiz}
                  isLoading={loading}
                >
                  <i className='bi bi-download fs-3'></i> &nbsp; Businesses
                </Button>

                <Button
                  size={SIZE.mini}
                  onClick={downloadQuotes}
                  isLoading={loading}
                >
                  <i className='bi bi-download fs-3'></i> &nbsp; Quotes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container  container-xxl '>
        <div className='app-main flex-column flex-row-fluid '>
          <div className='d-flex flex-column flex-column-fluid'>
            <div className='app-content '>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0 pt-6'>
                  <div className='w-100'>
                    <div className='d-flex flex-start flex-wrap gap-4'>
                      <div className='d-flex align-items-center flex-wrap gap-3 gap-xl-9'>
                        <div className='w-250px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7'>Industry</div>
                          &nbsp;
                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              { label: 'Show All', id: 'all' },
                              { label: 'Beauty salon', id: 'Beauty salon' },
                              { label: 'Cannabis', id: 'Cannabis' },
                              {
                                label: 'Fashion boutique',
                                id: 'Fashion boutique'
                              },
                              { label: 'Grocery', id: 'Grocery' },
                              { label: 'Hardware', id: 'Hardware' },
                              { label: 'Healthcare', id: 'Healthcare' },
                              { label: 'Hotel/lodging', id: 'Hotel/lodging' },
                              { label: 'Lodging', id: 'Lodging' },
                              { label: 'Non-profit', id: 'Non-profit' },
                              {
                                label: 'Online/e-commerce',
                                id: 'Online/e-commerce'
                              },
                              {
                                label: 'Professional services',
                                id: 'Professional services'
                              },
                              { label: 'Restaurant', id: 'Restaurant' },
                              { label: 'Retail', id: 'Retail' },
                              { label: 'Travel agency', id: 'Travel agency' },
                              { label: 'Wellness', id: 'Wellness' },
                              { label: 'Wholesale', id: 'Wholesale' },
                              { label: 'Other', id: 'Other' }
                            ]}
                            value={industry}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setIndustry(value)}
                          />
                        </div>

                        <div className='w-250px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7 me-2'>Volume</div>

                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              {
                                label: 'Show All',
                                id: 'all'
                              },
                              {
                                label: '1k - 10K',
                                id: '<10K'
                              },
                              {
                                label: '10K - 50K',
                                id: '10k-50k'
                              },
                              {
                                label: '50K - 100K',
                                id: '50k-100k'
                              },
                              {
                                label: '100K+',
                                id: '100K+'
                              }
                            ]}
                            value={volume}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setVolume(value)}
                          />
                        </div>

                        <div className='w-250px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7 me-2 w-300px'>
                            Merchant Services
                          </div>

                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              {
                                label: 'Show All',
                                id: 'all'
                              },
                              {
                                label: 'Yes',
                                id: 'yes'
                              },
                              {
                                label: 'No',
                                id: 'no'
                              }
                            ]}
                            value={merchant_services}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setMerchantServices(value)}
                          />
                        </div>

                        <div className='w-200px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7 me-2'>Accepts</div>

                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              {
                                label: 'Show All',
                                id: 'all'
                              },
                              { label: 'In person', id: 'In person' },
                              { label: 'Online', id: 'Online' },
                              { label: 'Over the phone', id: 'Over the phone' }
                            ]}
                            value={accepts}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setAccepts(value)}
                          />
                        </div>
                      </div>

                      <div className='d-flex align-items-center gap-4'>
                        <Button
                          size={SIZE.compact}
                          isLoading={loading}
                          onClick={getMerchants}
                          type='submit'
                          className='btn btn-sm btn-primary'
                        >
                          <i className='ki-duotone ki-filter fs-2 text-gray-500'></i>
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-body p-10'>
                  <div className='dt-container dt-bootstrap5 dt-empty-footer'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable'>
                        <thead>
                          <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Business Name
                              </span>
                            </th>

                            <th className='min-w-100px'>
                              <span className='dt-column-title'>Industry</span>
                            </th>

                            <th className='min-w-100px'>
                              <span className='dt-column-title'>Volume</span>
                            </th>

                            <th className='min-w-80px'>
                              <span className='dt-column-title'>
                                Merchant Services
                              </span>
                            </th>

                            <th className='min-w-100px'>
                              <span className='dt-column-title'>Payments</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Phone Number
                              </span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Email Address
                              </span>
                            </th>

                            <th className='min-w-60px'>
                              <span className='dt-column-title'>Statement</span>
                            </th>

                            <th className='min-w-60px'>
                              <span className='dt-column-title'>Quotes</span>
                            </th>

                            <th className='text-end min-w-70px dt-orderable-none'>
                              <span className='dt-column-title'></span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {loading && (
                            <tr>
                              <td colSpan={11} className='text-center'>
                                Loading...
                              </td>
                            </tr>
                          )}
                          {!loading && merchants.length === 0 && (
                            <tr>
                              <td colSpan={11} className='text-center'>
                                No Businesses Found
                              </td>
                            </tr>
                          )}

                          {merchants.map((merchant: any) => (
                            <tr key={merchant.id}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business_name}

                                    {merchant.tax_id && (
                                      <>
                                        <br />
                                        Tax Id: {merchant.tax_id}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.industry}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.volume}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.merchant_services.toUpperCase()}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.payments}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.phone}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.email}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <div className='d-flex flex-column justify-start flex-center'>
                                    {merchant?.statement?.url && (
                                      <a
                                        href={`${merchant?.statement?.url}`}
                                        target='new'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <i className='bi bi-download fs-3'></i>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <Button
                                    size={SIZE.mini}
                                    className='d-flex justify-content-center flex-column'
                                    onClick={() => {
                                      setSelected(merchant);
                                      setShow(true);
                                    }}
                                  >
                                    {merchant.meta.quotes_count}
                                  </Button>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <div className='d-flex justify-content-end flex-column'>
                                    <Button
                                      isLoading={loading}
                                      onClick={() => destroy(merchant.id)}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                      <i className='bi bi-trash fs-3'></i>
                                    </Button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='card-footer py-6'>
                  {Math.ceil(total / perPage) > 1 && (
                    <div className='d-flex align-items-center flex-wrap'>
                      <div className='d-flex align-items-center gap-3 w-200px'>
                        <span className='text-muted fs-7 fw-bold'>
                          Showing {merchants.length} out of {total} entries
                        </span>
                      </div>

                      <div className='d-flex align-items-center gap-3 w-500px'>
                        <Pagination
                          numPages={Math.ceil(total / perPage)}
                          currentPage={page}
                          onPageChange={({ nextPage }) => setPage(nextPage)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        onClose={() => setShow(false)}
        closeable
        isOpen={show}
        animate
        autoFocus
        size={ModalSize.auto}
        role={ROLE.dialog}
      >
        <ModalHeader>Quotes for: {selected?.business_name}</ModalHeader>
        <ModalBody>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body p-10'>
              <div className=' flex-column'>
                <div className='dt-container dt-bootstrap5 dt-empty-footer'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable'>
                      <thead>
                        <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Merchant Name
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Business Name
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Total savings
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Current Promotion
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Machine Cost
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>Monthly Fee</span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Interchange Fees
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>Status</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {loading && (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              Loading...
                            </td>
                          </tr>
                        )}
                        {!loading && quotes.length === 0 && (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              No Quotes yet. Check back later
                            </td>
                          </tr>
                        )}

                        {quotes.map((merchant: any) => (
                          <tr key={merchant?.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.user.merchant_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.business.business_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.total_saving}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.current_promotion}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.machine_cost}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.monthly_fee}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.interchange_fees}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.status.toUpperCase()}
                                </div>
                              </div>
                            </td>
                            <td>
                              {/* {merchant.admin_status === 'pending' &&
                                                                <div className="d-flex align-items-center">

                                                                    <Button
                                                                        onClick={() => update(merchant.id, 'accepted')}
                                                                        className="btn btn-sm btn-primary"
                                                                        isLoading={loading}
                                                                    >
                                                                        Accept
                                                                    </Button>
                                                                    &nbsp;
                                                                    <Button
                                                                        onClick={() => update(merchant.id, 'rejected')}
                                                                        className="btn btn-sm btn-primary"
                                                                        isLoading={loading}
                                                                    >
                                                                        Reject
                                                                    </Button>

                                                                </div>
                                                            } */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={() => setShow(false)}>Close</ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
