import { Button } from 'baseui/button';
import { KIND, Notification } from 'baseui/notification';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';

export default function Reset() {
  const [form, setForm] = useState({
    email: '',
    token: '',
    confirmPassword: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    token: '',
    confirmPassword: '',
    password: ''
  });
  const [loginError, setLoginError] = useState(false) as any;
  const [step, setStep] = useState(1);
  const [passwrordError, setPasswordError] = useState('');

  const reset = async () => {
    if (loading) return;

    //check if email is provided
    if (form.email === '') {
      setErrors({
        email: 'Please enter your email to continue',
        token: '',
        password: '',
        confirmPassword: ''
      });
      return;
    } else {
      setErrors({
        email: '',
        token: '',
        password: '',
        confirmPassword: ''
      });
    }

    if (step > 1) {
      if (form.token === '') {
        setErrors({
          email: '',
          token: 'Please enter your verification code to continue',
          password: '',
          confirmPassword: ''
        });
        return;
      } else {
        setErrors({
          email: '',
          token: '',
          password: '',
          confirmPassword: ''
        });
      }

      if (!validatePassword(form.password)) return;
    }

    setLoading(true);
    setLoginError(false);

    try {
      if (step === 1) {
        let { data } = await Api.post('auth/reset-password', form);

        if (data.message) {
          toaster.positive(data.message);
          setStep(2);
        }
      } else {
        let { data } = await Api.put('auth/reset-password', form);

        if (data.message) {
          toaster.positive(data.message);
          window.location.href = '/login';
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setLoginError(true);
      if (step === 1) {
        setLoginError('Invalid email');
      } else {
        setLoginError('Invalid Verification Code provided');
      }
    }
  };

  function validatePassword(password: string) {
    var errors = [];

    if (!/(?=.*[a-z])/.test(password)) {
      errors.push('at least one lowercase letter');
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push('at least one uppercase letter');
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push('at least one number');
    }
    if (!/[!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?]+/g.test(password)) {
      errors.push('at least one special character');
    }
    if (password.length < 6) {
      errors.push('at least 6 characters long');
    }

    if (password !== form.confirmPassword) {
      errors.push('Passwords do not match.');
    }

    if (errors.length > 0) {
      setPasswordError('Password is missing: ' + errors.join(', '));
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  }

  return (
    <>
      <div className='d-flex flex-column flex-root flex-center'>
        <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
          {/* <div className="d-flex flex-lg-row-fluid">

                        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">


                            <div className="text-gray-600 fs-base text-center fw-semibold">
                                Powerful and flexible tool that helps you grow your business.
                            </div>

                        </div>

                    </div> */}

          <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12'>
            <div className='bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10'>
              <img
                className='theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
                src='/assets/media/logos/custom-1.png'
                alt=''
              />

              <div className='d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px'>
                <div className='d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20'>
                  <div
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    id='kt_sign_in_form'
                  >
                    <div className='text-center mb-11'>
                      <h1 className='text-gray-900 fw-bolder mb-3'>
                        Reset Password
                      </h1>

                      {/* <div className="text-gray-500 fw-semibold fs-6">
                                                Unlock your merchant account
                                            </div> */}
                    </div>

                    <div className='fv-row mb-8 fv-plugins-icon-container'>
                      {loginError && (
                        <Notification
                          kind={KIND.negative}
                          overrides={{
                            Body: {
                              style: {
                                width: '100%',
                                marginLeft: '0px'
                              }
                            }
                          }}
                        >
                          <div className='text-center text-danger'>
                            {loginError}
                          </div>
                        </Notification>
                      )}

                      <input
                        type='text'
                        value={form.email}
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                        placeholder='Email'
                        name='email'
                        className='form-control bg-transparent'
                      />

                      {errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div
                            data-field='email'
                            data-validator='notEmpty'
                            className='fv-help-block'
                          >
                            {errors.email}
                          </div>
                        </div>
                      )}
                    </div>

                    {step > 1 && (
                      <>
                        <div className='fv-row mt-2 mb-2 fv-plugins-icon-container'>
                          <input
                            type='number'
                            value={form.token}
                            onChange={(e) =>
                              setForm({ ...form, token: e.target.value })
                            }
                            placeholder='Password reset code'
                            className='form-control bg-transparent'
                          />

                          {errors.token && (
                            <div className='fv-plugins-message-container'>
                              <div
                                data-field='password'
                                data-validator='notEmpty'
                                className='fv-help-block'
                              >
                                {errors.token}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='fv-row mb-2 fv-plugins-icon-container'>
                          <input
                            type='password'
                            value={form.password}
                            onChange={(e) =>
                              setForm({ ...form, password: e.target.value })
                            }
                            placeholder='Password'
                            name='password'
                            className='form-control bg-transparent'
                          />

                          {passwrordError !== '' && (
                            <div className='fv-plugins-message-container'>
                              <div
                                data-field='password'
                                data-validator='notEmpty'
                                className='fv-help-block'
                              >
                                {passwrordError}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='fv-row mb-20 fv-plugins-icon-container'>
                          <input
                            type='password'
                            value={form.confirmPassword}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                confirmPassword: e.target.value
                              })
                            }
                            placeholder='Confirm Password'
                            name='password'
                            className='form-control bg-transparent'
                          />

                          {errors.confirmPassword && (
                            <div className='fv-plugins-message-container'>
                              <div
                                data-field='password'
                                data-validator='notEmpty'
                                className='fv-help-block'
                              >
                                {errors.confirmPassword}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    <div className='d-grid mb-10'>
                      <Button
                        onClick={reset}
                        isLoading={loading}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => {
                              return {
                                width: '100%'
                              };
                            }
                          }
                        }}
                        className='btn btn-primary'
                      >
                        <span className='indicator-label'>
                          {step === 1 ? 'Send Reset Code' : 'Reset Password'}
                        </span>
                      </Button>
                    </div>

                    <div className='text-gray-500 text-center fw-semibold fs-6'>
                      Already have an account?
                      <Link to='/login' className='link-primary'>
                        {' '}
                        Sign In
                      </Link>
                    </div>

                    <div className='text-gray-500 text-center fw-semibold fs-6 mt-10'>
                      <a
                        target='new'
                        href='https://merchantstacks.com/terms-of-service/'
                        className='link-primary'
                      >
                        Terms of Service{' '}
                      </a>
                      <span className='w-15px'> | </span>
                      <a
                        target='new'
                        href='https://merchantstacks.com/privacy-policy/'
                        className='link-primary'
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
