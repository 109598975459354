import { Button, SIZE } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { Modal, ModalBody, ModalHeader, ROLE } from 'baseui/modal';
import { Pagination } from 'baseui/pagination';
import { toaster } from 'baseui/toast';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';

export default function Users() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [merchants, setMerchants] = useState([]) as any;
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [selected, setSelected] = useState({}) as any;
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    name: '',
    // merchantName: "",
    email: '',
    phone: '',
    password: ''
  }) as any;

  useEffect(() => {
    getMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getMerchants = async () => {
    try {
      let { data } = await Api.get(`admin/customers?page=${page}`);

      setMerchants(data.data);
      setTotal(data.meta.total);
      setPerPage(data.meta.per_page);
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      try {
        let { message } = error.response.data;

        if (message) {
          return toaster.negative(message);
        }
      } catch (e) {
        toaster.negative('An error occurred');
      }
    }
  };

  const destroy = async (id: number) => {
    if (loading) return;

    setLoading(true);

    try {
      await Api.delete(`admin/user/${id}`);

      getMerchants();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const download = async () => {
    if (loading) return;

    setLoading(true);

    try {
      let response = await Api.get('admin/customers-download', {
        responseType: 'blob'
      });

      // Create a Blob from the data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element for downloading
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Customers.csv'; // File name
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const edit = (merchant: any) => {
    //populate form
    setForm({
      name: merchant.name,
      merchantName: merchant.merchant_name,
      email: merchant.email,
      phone: merchant.phone,
      password: ''
    });

    setSelected(merchant);

    setShow(true);
  };

  const update = async () => {
    if (loading) return;

    //check all fields
    if (!form.name) {
      return toaster.negative('Name is required');
    }

    if (!form.email) {
      return toaster.negative('Email is required');
    }

    if (!form.phone) {
      return toaster.negative('Phone is required');
    }

    setLoading(true);

    try {
      let clone = { ...form };

      if (clone.password === '') delete clone.password;

      let { data } = await Api.put('/admin/user/' + selected.id, clone);

      toaster.positive(data.message);

      getMerchants();
      setShow(false);
      setSelected({});
      setForm({
        name: '',
        merchantName: '',
        email: '',
        phone: '',
        password: ''
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar  py-6 '>
        <div
          id='kt_app_toolbar_container'
          className='app-container  container-xxl d-flex align-items-start '
        >
          <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/dashboard'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-white fs-3'></i>
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  Customers
                </li>
              </ul>
            </div>

            <div className='d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-6 pb-18 py-lg-13'>
              <div className='page-title d-flex align-items-center me-3'>
                <img
                  alt='Logo'
                  src='/assets/media/layer.svg'
                  className='h-60px me-5'
                />

                <h1 className='page-heading d-flex text-white fw-bolder fs-2 flex-column justify-content-center my-0'>
                  Customers
                  <span className='page-desc text-white opacity-50 fs-6 fw-bold pt-4'>
                    Customers Management
                  </span>
                </h1>
              </div>

              <div className='d-flex gap-4 gap-lg-13'>
                <Button size={SIZE.mini} onClick={download} isLoading={loading}>
                  <i className='bi bi-download fs-3'></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container  container-xxl '>
        <div className='app-main flex-column flex-row-fluid '>
          <div className='d-flex flex-column flex-column-fluid'>
            <div className='app-content '>
              <div className='card mb-5 mb-xl-10'>
                {/* <div className="card-header border-0 pt-6">

                                    <div className="card-title">

                                        <div className="d-flex align-items-center position-relative my-1">
                                            <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
                                            <input type="text" className="form-control form-control-solid w-250px ps-12" placeholder="Search Merchants" />
                                        </div>

                                    </div>

                                </div> */}

                <div className='card-body p-10'>
                  <div className='dt-container dt-bootstrap5 dt-empty-footer'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable'>
                        <thead>
                          <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Name</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Phone Number
                              </span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Email Address
                              </span>
                            </th>

                            <th className='text-end min-w-70px dt-orderable-none'>
                              <span className='dt-column-title'>Actions</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {loading && (
                            <tr>
                              <td colSpan={6} className='text-center'>
                                Loading...
                              </td>
                            </tr>
                          )}
                          {!loading && merchants.length === 0 && (
                            <tr>
                              <td colSpan={6} className='text-center'>
                                No Customers Found
                              </td>
                            </tr>
                          )}

                          {merchants.map((merchant: any) => (
                            <tr key={merchant.id}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.name}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.phone}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.email}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <div className='d-flex justify-content-end flex-column'>
                                    <ButtonGroup>
                                      <Button
                                        isLoading={loading}
                                        onClick={() => edit(merchant)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <i className='bi bi-pen fs-3'></i>
                                      </Button>

                                      <Button
                                        isLoading={loading}
                                        onClick={() => destroy(merchant.id)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <i className='bi bi-trash fs-3'></i>
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='card-footer py-6'>
                  {Math.ceil(total / perPage) > 1 && (
                    <div className='d-flex align-items-center flex-wrap'>
                      <div className='d-flex align-items-center gap-3 w-200px'>
                        <span className='text-muted fs-7 fw-bold'>
                          Showing {merchants.length} out of {total} entries
                        </span>
                      </div>

                      <div className='d-flex align-items-center gap-3 w-500px'>
                        <Pagination
                          numPages={Math.ceil(total / perPage)}
                          currentPage={page}
                          onPageChange={({ nextPage }) => setPage(nextPage)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        onClose={() => setShow(false)}
        closeable
        isOpen={show}
        animate
        autoFocus
        //size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Edit Customer: {selected.name}</ModalHeader>
        <ModalBody>
          <div className=''>
            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'> Name</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                placeholder='John Doe'
              />
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'>Email</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='email'
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                placeholder='john@merchantstacks.com'
              />
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'>Phone</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                value={form.phone}
                onChange={(e) => setForm({ ...form, phone: e.target.value })}
                placeholder='+1 123 456 7890'
              />
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'>Update Password</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='password'
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                placeholder='Strong password'
              />
              <span>Leave empty to keep current password</span>
            </div>

            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <Button
                isLoading={loading}
                onClick={update}
                className='btn btn-lg btn-primary'
              >
                Update Customer
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
