import { Pagination } from 'baseui/pagination';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';

export default function AdminDashboard() {
  const [stats, setStats] = useState({
    customers: 0,
    businesses: 0,
    quotes: 0,
    merchants: 0,
    admins: 0
  }) as any;
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [quotes, setQuotes] = useState([]) as any;
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getStats = async () => {
    try {
      let { data } = await Api.get('admin/stats');

      setStats({ stats, ...data });
    } catch (error) {
      console.error(error);
    }
  };

  const getQuotes = async () => {
    setLoading(true);

    try {
      let { data } = await Api.get('admin/quotes?page=' + page);

      setQuotes(data.data);
      setTotal(data.meta.total);
      setPerPage(data.meta.per_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  /*
  const update = async (id: number, status: string) => {
    if (loading) return;

    setLoading(true);

    try {
      await Api.put('admin/quotes/' + id, { adminStatus: status });

      getQuotes();

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  */

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar  py-6 '>
        <div
          id='kt_app_toolbar_container'
          className='app-container  container-xxl d-flex align-items-start '
        >
          <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/dashboard'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-white fs-3'></i>
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  Dashboard
                </li>
              </ul>
            </div>

            <div className='d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-6 pb-18 py-lg-13'>
              <div className='page-title d-flex align-items-center me-3'>
                <img
                  alt='Logo'
                  src='/assets/media/layer.svg'
                  className='h-60px me-5'
                />

                <h1 className='page-heading d-flex text-white fw-bolder fs-2 flex-column justify-content-center my-0'>
                  Merchant Stacks
                  <span className='page-desc text-white opacity-50 fs-6 fw-bold pt-4'>
                    Admin Dashboard
                  </span>
                </h1>
              </div>

              <div className='d-flex gap-4 gap-lg-13'>
                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.customers.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>Customers</div>
                </div>

                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.businesses.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>
                    Businesses
                  </div>
                </div>

                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.merchants.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>Merchants</div>
                </div>

                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.quotes.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>Quotes</div>
                </div>

                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.admins.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>Admins</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container container-xxl'>
        <div className='app-content '>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body p-10'>
              <div className=' flex-column'>
                <div className='dt-container dt-bootstrap5 dt-empty-footer'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable'>
                      <thead>
                        <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Merchant Name
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Business Name
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Total savings
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Current Promotion
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Machine Cost
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>Monthly Fee</span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>
                              Interchange Fees
                            </span>
                          </th>

                          <th className='min-w-125px'>
                            <span className='dt-column-title'>Status</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {loading && (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              Loading...
                            </td>
                          </tr>
                        )}
                        {!loading && quotes.length === 0 && (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              No Quotes yet. Check back later
                            </td>
                          </tr>
                        )}

                        {quotes.map((merchant: any) => (
                          <tr key={merchant?.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.user.merchant_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.business.business_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.total_saving}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.current_promotion}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.machine_cost}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.monthly_fee}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.interchange_fees}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {merchant?.status.toUpperCase()}
                                </div>
                              </div>
                            </td>
                            <td>
                              {/* {merchant.admin_status === 'pending' &&
                                                                <div className="d-flex align-items-center">

                                                                    <Button
                                                                        onClick={() => update(merchant.id, 'accepted')}
                                                                        className="btn btn-sm btn-primary"
                                                                        isLoading={loading}
                                                                    >
                                                                        Accept
                                                                    </Button>
                                                                    &nbsp;
                                                                    <Button
                                                                        onClick={() => update(merchant.id, 'rejected')}
                                                                        className="btn btn-sm btn-primary"
                                                                        isLoading={loading}
                                                                    >
                                                                        Reject
                                                                    </Button>

                                                                </div>
                                                            }
                                                            */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer py-6'>
              {Math.ceil(total / perPage) > 1 && (
                <div className='d-flex align-items-center flex-wrap'>
                  <div className='d-flex align-items-center gap-3 w-200px'>
                    <span className='text-muted fs-7 fw-bold'>
                      Showing {quotes.length} out of {total} quotes
                    </span>
                  </div>

                  <div className='d-flex align-items-center gap-3 w-500px'>
                    <Pagination
                      numPages={Math.ceil(total / perPage)}
                      currentPage={page}
                      onPageChange={({ nextPage }) => setPage(nextPage)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
