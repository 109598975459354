import { Button, SIZE } from 'baseui/button';
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal';
import { Pagination } from 'baseui/pagination';
import { Select } from 'baseui/select';
import { toaster } from 'baseui/toast';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';

export default function MerchantDashboard() {
  const [stats, setStats] = useState({
    accepted: 0,
    pending: 0,
    rejected: 0
  }) as any;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [businesses, setBusinesses] = useState([]) as any;
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null) as any;
  const [form, setForm] = useState({
    currentPromotion: 0,
    totalSaving: 0,
    interchangeFees: 0,
    machineCost: 0,
    monthlyFee: 0
  }) as any;
  const [volume, setVolume] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [industry, setIndustry] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [merchant_services, setMerchantServices] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;
  const [accepts, setAccepts] = useState([
    {
      label: 'Show All',
      id: 'all'
    }
  ]) as any;

  const [errors, setErrors] = useState({
    currentPromotion: false,
    totalSaving: false,
    interchangeFees: false,
    machineCost: false,
    monthlyFee: false
  }) as any;

  useEffect(() => {
    getBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, volume, industry, merchant_services, accepts]);

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStats = async () => {
    try {
      let { data } = await Api.get('merchants/stats');

      setStats({ stats, ...data });
    } catch (error) {
      console.error(error);
    }
  };

  const getBusinesses = async () => {
    if (loading) return;

    setLoading(true);

    try {
      let { data } = await Api.get(
        `merchants/businesses?page=${page}&volume=${volume[0]?.id}&industry=${industry[0]?.id}&merchantServices=${merchant_services[0].id}&accepts=${accepts[0]?.id}`
      );

      setBusinesses(data.data);
      setTotal(data.meta.total);
      setPerPage(data.meta.per_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const save = async () => {
    //validate numbers only regex
    const numberRegex = /^[0-9]*$/;

    //check fields
    if (!form.currentPromotion) {
      return setErrors({
        ...errors,
        currentPromotion: 'Current Promotion is required.'
      });
    } else {
      setErrors({ ...errors, currentPromotion: false });
    }

    if (!form.totalSaving) {
      return setErrors({
        ...errors,
        totalSaving: 'Total Savings is required.'
      });
    } else {
      setErrors({ ...errors, totalSaving: false });
    }

    if (!form.interchangeFees) {
      return setErrors({
        ...errors,
        interchangeFees: 'Interchange Fees is required.'
      });
    } else {
      setErrors({ ...errors, interchangeFees: false });
    }

    if (!form.machineCost) {
      return setErrors({ ...errors, machineCost: 'Machine Cost is required.' });
    } else {
      setErrors({ ...errors, machineCost: false });
    }

    if (!form.monthlyFee) {
      return setErrors({ ...errors, monthlyFee: 'Monthly Fee is required.' });
    } else {
      setErrors({ ...errors, monthlyFee: false });
    }

    //validate that all inputs are numbers
    if (!numberRegex.test(form.currentPromotion)) {
      return setErrors({
        ...errors,
        currentPromotion: 'Current Promotion must be a number.'
      });
    } else {
      setErrors({ ...errors, currentPromotion: false });
    }

    if (!numberRegex.test(form.interchangeFees)) {
      return setErrors({
        ...errors,
        interchangeFees: 'Interchange Fees must be a number.'
      });
    } else {
      setErrors({ ...errors, interchangeFees: false });
    }

    if (!numberRegex.test(form.machineCost)) {
      return setErrors({
        ...errors,
        machineCost: 'Machine Cost must be a number.'
      });
    } else {
      setErrors({ ...errors, machineCost: false });
    }

    if (!numberRegex.test(form.monthlyFee)) {
      return setErrors({
        ...errors,
        monthlyFee: 'Monthly Fee must be a number.'
      });
    } else {
      setErrors({ ...errors, monthlyFee: false });
    }

    if (!numberRegex.test(form.totalSaving)) {
      return setErrors({
        ...errors,
        totalSaving: 'Total Savings must be a number.'
      });
    } else {
      setErrors({ ...errors, totalSaving: false });
    }

    setLoading(true);

    try {
      await Api.post('merchants/quote', {
        ...form,
        businessId: selected.id,
        status: 'pending'
      });

      setLoading(false);

      toaster.positive('Quote submitted successfully');

      setShow(false);

      getBusinesses();

      setErrors({
        currentPromotion: false,
        totalSaving: false,
        interchangeFees: false,
        machineCost: false,
        monthlyFee: false
      });
      setForm({
        currentPromotion: 0,
        totalSaving: 0,
        interchangeFees: 0,
        machineCost: 0,
        monthlyFee: 0
      });
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      try {
        let { message } = error.response.data;

        if (message) {
          return toaster.negative(message);
        }
      } catch (e) {
        toaster.negative('An error occurred');
      }
    }
  };

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar  py-6 '>
        <div
          id='kt_app_toolbar_container'
          className='app-container  container-xxl d-flex align-items-start '
        >
          <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/dashboard'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-white fs-3'></i>
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  Dashboard
                </li>
              </ul>
            </div>

            <div className='d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-6 pb-18 py-lg-13'>
              <div className='page-title d-flex align-items-center me-3'>
                <img
                  alt='Logo'
                  src='/assets/media/layer.svg'
                  className='h-60px me-5'
                />

                <h1 className='page-heading d-flex text-white fw-bolder fs-2 flex-column justify-content-center my-0'>
                  Merchant Stacks
                  <span className='page-desc text-white opacity-50 fs-6 fw-bold pt-4'>
                    View Businesses
                  </span>
                </h1>
              </div>

              <div className='d-flex gap-4 gap-lg-13'>
                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.accepted.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>Accepted</div>
                </div>

                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.pending.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>Pending</div>
                </div>

                <div className='d-flex flex-column'>
                  <span className='text-white fw-bold fs-3 mb-1'>
                    {stats?.rejected.toLocaleString()}
                  </span>

                  <div className='text-white opacity-50 fw-bold'>Rejected</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container  container-xxl '>
        <div className='app-main flex-column flex-row-fluid '>
          <div className='d-flex flex-column flex-column-fluid'>
            <div className='app-content '>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0 pt-6'>
                  <div className='w-100'>
                    <div className='d-flex flex-start flex-wrap gap-4'>
                      <div className='d-flex align-items-center flex-wrap gap-3 gap-xl-9'>
                        <div className='w-250px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7'>Industry</div>
                          &nbsp;
                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              { label: 'Show All', id: 'all' },
                              { label: 'Beauty salon', id: 'Beauty salon' },
                              { label: 'Cannabis', id: 'Cannabis' },
                              {
                                label: 'Fashion boutique',
                                id: 'Fashion boutique'
                              },
                              { label: 'Grocery', id: 'Grocery' },
                              { label: 'Hardware', id: 'Hardware' },
                              { label: 'Healthcare', id: 'Healthcare' },
                              { label: 'Hotel/lodging', id: 'Hotel/lodging' },
                              { label: 'Lodging', id: 'Lodging' },
                              { label: 'Non-profit', id: 'Non-profit' },
                              {
                                label: 'Online/e-commerce',
                                id: 'Online/e-commerce'
                              },
                              {
                                label: 'Professional services',
                                id: 'Professional services'
                              },
                              { label: 'Restaurant', id: 'Restaurant' },
                              { label: 'Retail', id: 'Retail' },
                              { label: 'Travel agency', id: 'Travel agency' },
                              { label: 'Wellness', id: 'Wellness' },
                              { label: 'Wholesale', id: 'Wholesale' },
                              { label: 'Other', id: 'Other' }
                            ]}
                            value={industry}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setIndustry(value)}
                          />
                        </div>

                        <div className='w-250px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7 me-2'>Volume</div>

                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              {
                                label: 'Show All',
                                id: 'all'
                              },
                              {
                                label: '1k - 10K',
                                id: '<10K'
                              },
                              {
                                label: '10K - 50K',
                                id: '10k-50k'
                              },
                              {
                                label: '50K - 100K',
                                id: '50k-100k'
                              },
                              {
                                label: '100K+',
                                id: '100K+'
                              }
                            ]}
                            value={volume}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setVolume(value)}
                          />
                        </div>

                        <div className='w-250px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7 me-2 w-300px'>
                            Existing Services
                          </div>

                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              {
                                label: 'Show All',
                                id: 'all'
                              },
                              {
                                label: 'Yes',
                                id: 'yes'
                              },
                              {
                                label: 'No',
                                id: 'no'
                              }
                            ]}
                            value={merchant_services}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setMerchantServices(value)}
                          />
                        </div>

                        <div className='w-200px d-flex align-items-center fw-bold'>
                          <div className='text-muted fs-7 me-2'>Accepts</div>

                          <Select
                            size={SIZE.mini}
                            isLoading={loading}
                            clearable={false}
                            searchable={false}
                            options={[
                              {
                                label: 'Show All',
                                id: 'all'
                              },
                              { label: 'In person', id: 'In person' },
                              { label: 'Online', id: 'Online' },
                              { label: 'Over the phone', id: 'Over the phone' }
                            ]}
                            value={accepts}
                            labelKey='label'
                            valueKey='id'
                            onChange={({ value }) => setAccepts(value)}
                          />
                        </div>
                      </div>

                      <div className='d-flex align-items-center gap-4'>
                        <Button
                          size={SIZE.compact}
                          isLoading={loading}
                          onClick={getBusinesses}
                          type='submit'
                          className='btn btn-sm btn-primary'
                        >
                          <i className='ki-duotone ki-filter fs-2 text-gray-500'></i>
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-body p-10'>
                  <div className='dt-container dt-bootstrap5 dt-empty-footer'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable'>
                        <thead>
                          <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Business Name
                              </span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Industry</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Volume</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>
                                Existing Services
                              </span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Accepts</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Phone</span>
                            </th>

                            <th className='min-w-125px'>
                              <span className='dt-column-title'>Statement</span>
                            </th>

                            <th className='text-end min-w-70px dt-orderable-none'>
                              <span className='dt-column-title'>Actions</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {loading && (
                            <tr>
                              <td colSpan={7} className='text-center'>
                                Loading...
                              </td>
                            </tr>
                          )}
                          {!loading && businesses.length === 0 && (
                            <tr>
                              <td colSpan={7} className='text-center'>
                                No Businesses Found
                              </td>
                            </tr>
                          )}

                          {businesses.map((merchant: any) => (
                            <tr key={merchant.id}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.business_name}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.industry}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.volume}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.merchant_services.toUpperCase()}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.payments}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {merchant.phone}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center justify-content-start'>
                                  <div className='d-flex flex-column flex-center'>
                                    {merchant?.statement?.url && (
                                      <a
                                        href={`${merchant?.statement?.url}`}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <i className='bi bi-download fs-3'></i>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <div className='d-flex justify-content-end flex-column'>
                                    <Button
                                      size={SIZE.mini}
                                      onClick={() => {
                                        setSelected(merchant);
                                        setShow(true);
                                      }}
                                    >
                                      Offer Quote
                                    </Button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className='card-footer py-6'>
                  {Math.ceil(total / perPage) > 1 && (
                    <div className='d-flex align-items-center flex-wrap'>
                      <div className='d-flex align-items-center gap-3 w-200px'>
                        <span className='text-muted fs-7 fw-bold'>
                          Showing {businesses.length} out of {total} businesses
                        </span>
                      </div>

                      <div className='d-flex align-items-center gap-3 w-500px'>
                        <Pagination
                          numPages={Math.ceil(total / perPage)}
                          currentPage={page}
                          onPageChange={({ nextPage }) => setPage(nextPage)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        onClose={() => setShow(false)}
        closeable
        isOpen={show}
        animate
        autoFocus
        //size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Quote to {selected?.business_name}</ModalHeader>
        <ModalBody>
          <div className='flex-row mb-10'>
            Volume &bull; {selected?.volume} - Industry &bull;{' '}
            {selected?.industry} - Existing merchant services &bull;{' '}
            {selected?.merchant_services}
          </div>
          <div>
            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <label className='form-label mb-3'>Current Promotion</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                value={form.currentPromotion}
                onChange={(e) =>
                  setForm({ ...form, currentPromotion: e.target.value })
                }
                placeholder='$500 cash back'
              />
              {errors.currentPromotion && (
                <div className='fv-plugins-message-container'>
                  <div
                    data-field='currentPromotion'
                    data-validator='notEmpty'
                    className='fv-help-block'
                  >
                    {errors.currentPromotion}
                  </div>
                </div>
              )}
            </div>
            <div className='mb-10 w-100 d-flex flex-row'>
              <div className='mr-10 fv-row fv-plugins-icon-container'>
                <label className='form-label mb-3'>Total Savings</label>
                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  value={form.totalSaving}
                  onChange={(e) =>
                    setForm({ ...form, totalSaving: e.target.value })
                  }
                  placeholder='$1500'
                />
                {errors.totalSaving && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='totalSaving'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.totalSaving}
                    </div>
                  </div>
                )}
              </div>
              <div className='w-20px' />

              <div className='mr-10 fv-row fv-plugins-icon-container'>
                <label className='form-label mb-3'>Interchange Fees </label>
                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  value={form.interchangeFees}
                  onChange={(e) =>
                    setForm({ ...form, interchangeFees: e.target.value })
                  }
                  placeholder='$1500'
                />
                {errors.interchangeFees && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='interchangeFees'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.interchangeFees}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='mb-10 w-100 d-flex flex-row'>
              <div className='fv-row  fv-plugins-icon-container'>
                <label className='form-label mb-3'>Machine Cost</label>
                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  value={form.machineCost}
                  onChange={(e) =>
                    setForm({ ...form, machineCost: e.target.value })
                  }
                  placeholder='$500 cash back'
                />
                {errors.machineCost && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='machineCost'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.machineCost}
                    </div>
                  </div>
                )}
              </div>

              <div className='w-20px' />

              <div className='fv-row ml-10 fv-plugins-icon-container'>
                <label className='form-label mb-3'>Monthly Fee</label>
                <input
                  className='form-control form-control-lg form-control-solid'
                  type='text'
                  value={form.monthlyFee}
                  onChange={(e) =>
                    setForm({ ...form, monthlyFee: e.target.value })
                  }
                  placeholder='$0'
                />
                {errors.monthlyFee && (
                  <div className='fv-plugins-message-container'>
                    <div
                      data-field='monthlyFee'
                      data-validator='notEmpty'
                      className='fv-help-block'
                    >
                      {errors.monthlyFee}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={loading} onClick={save}>
            Save Quote
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
