import { BaseProvider, DarkTheme } from 'baseui';
import { PLACEMENT, ToasterContainer } from 'baseui/toast';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-monolithic';
import { Provider as StyletronProvider } from 'styletron-react';
import AdminDashboard from './Admin/AdminDashboard';
import CreateMerchants from './Admin/CreateMerchant';
import Customers from './Admin/Customers';
import Merchants from './Admin/Merchants';
import Quotes from './Admin/Quotes';
import Users from './Admin/Users';
import Api from './Api';
import AddBusiness from './Customer/AddBusiness';
import Businesses from './Customer/Businesses';
import CustomerDashboard from './Customer/Dashboard';
import MerchantDashboard from './Merchant/MerchantDashboard';
import MerchantQuotes from './Merchant/Quotes';
import Landing from './Pages/Landing';
import Login from './Pages/Login';
import Reset from './Pages/Reset';
import AdminLayout from './layout/AdminLayout';
import CustomerLayout from './layout/CustomerLayout';
import MerchantLayout from './layout/MerchantLayout';
const engine = new Styletron();

function App() {
  const [signedIn, setSignedIn] = React.useState(false);
  const [user, setUser] = React.useState({} as any);

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getSession();
    }
  }, []);

  const getSession = async () => {
    try {
      const response = await Api.get('auth/me');
      if (response.status === 200) {
        setSignedIn(true);
        setUser(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={DarkTheme}>
        <ToasterContainer autoHideDuration={800} placement={PLACEMENT.topRight}>
          <BrowserRouter>
            <Routes>
              {signedIn ? (
                <>
                  {user.role === 'customer' && (
                    <Route path='/' element={<CustomerLayout />}>
                      <Route
                        path='/dashboard'
                        element={<CustomerDashboard />}
                        index
                      />

                      <Route path='/' element={<CustomerDashboard />} />

                      <Route path='/add-business' element={<AddBusiness />} />

                      <Route path='/businesses' element={<Businesses />} />
                    </Route>
                  )}

                  {user.role === 'admin' && (
                    <Route path='/' element={<AdminLayout />}>
                      <Route path='/' element={<AdminDashboard />} />

                      <Route
                        path='/dashboard'
                        element={<AdminDashboard />}
                        index
                      />

                      <Route path='/businesses' element={<Customers />} />

                      <Route path='/customers' element={<Users />} />

                      <Route path='/merchants' element={<Merchants />} />

                      <Route
                        path='/merchants/create'
                        element={<CreateMerchants />}
                      />

                      <Route path='/quotes' element={<Quotes />} />
                    </Route>
                  )}

                  {user.role === 'merchant' && (
                    <Route path='/' element={<MerchantLayout />}>
                      <Route
                        path='/dashboard'
                        element={<MerchantDashboard />}
                        index
                      />
                      <Route path='/' element={<MerchantDashboard />} />
                      <Route path='/quotes' element={<MerchantQuotes />} />
                    </Route>
                  )}
                </>
              ) : (
                <>
                  <Route path='/' index element={<Landing />} />
                  <Route path='*' element={<Login />} />
                </>
              )}
              <Route path='/login' element={<Login />} />
              <Route path='/reset' element={<Reset />} />
            </Routes>
          </BrowserRouter>
        </ToasterContainer>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
