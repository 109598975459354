import { Button } from 'baseui/button';
import { KIND, Notification } from 'baseui/notification';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';

export default function Login() {
  const [form, setForm] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });
  const [loginError, setLoginError] = useState(false);

  const login = async (e: any) => {
    e.preventDefault();

    //check email
    if (form.email === '') {
      setErrors({
        email: 'Please enter your email to continue',
        password: ''
      });
      return;
    } else {
      setErrors({
        email: '',
        password: ''
      });
    }

    //check password
    if (form.password === '') {
      setErrors({
        email: '',
        password: 'Please enter your password to continue'
      });
      return;
    } else {
      setErrors({
        email: '',
        password: ''
      });
    }

    if (loading) return;

    setLoading(true);

    try {
      let { data } = await Api.post('auth/login', form);

      //save token
      localStorage.setItem('token', data.token.token);

      setLoading(false);

      // toaster.positive("Login successful")
      setLoginError(false);

      window.location.href = '/dashboard';
    } catch (error: any) {
      console.error(error);

      setLoading(false);
      setLoginError(true);

      try {
        let { message } = error.response.data;

        if (message) {
          return toaster.negative(message);
        }
      } catch (e) {
        toaster.negative('An error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='d-flex flex-column flex-root flex-center'>
        <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
          {/* <div className="d-flex flex-lg-row-fluid">

                        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">


                            <div className="text-gray-600 fs-base text-center fw-semibold">
                                Powerful and flexible tool that helps you grow your business.
                            </div>

                        </div>

                    </div> */}

          <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12'>
            <div className='bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10'>
              <img
                className='theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
                src='/assets/media/logos/custom-1.png'
                alt=''
              />

              <div className='d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px'>
                <div className='d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20'>
                  <div
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    id='kt_sign_in_form'
                  >
                    <div className='text-center mb-11'>
                      <h1 className='text-gray-900 fw-bolder mb-3'>Sign In</h1>

                      {/* <div className="text-gray-500 fw-semibold fs-6">
                                                Unlock your merchant account
                                            </div> */}
                    </div>

                    <div className='fv-row mb-8 fv-plugins-icon-container'>
                      {loginError && (
                        <Notification
                          kind={KIND.negative}
                          overrides={{
                            Body: {
                              style: {
                                width: '100%',
                                marginLeft: '0px'
                              }
                            }
                          }}
                        >
                          <div className='text-center text-danger'>
                            Invalid email or password
                          </div>
                        </Notification>
                      )}

                      <input
                        type='text'
                        value={form.email}
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                        placeholder='Email'
                        name='email'
                        className='form-control bg-transparent'
                      />

                      {errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div
                            data-field='email'
                            data-validator='notEmpty'
                            className='fv-help-block'
                          >
                            {errors.email}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-20 fv-plugins-icon-container'>
                      <input
                        type='password'
                        value={form.password}
                        onChange={(e) =>
                          setForm({ ...form, password: e.target.value })
                        }
                        placeholder='Password'
                        name='password'
                        className='form-control bg-transparent'
                      />

                      {errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div
                            data-field='password'
                            data-validator='notEmpty'
                            className='fv-help-block'
                          >
                            {errors.password}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='d-grid mb-10'>
                      <Button
                        onClick={login}
                        isLoading={loading}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => {
                              return {
                                width: '100%'
                              };
                            }
                          }
                        }}
                        className='btn btn-primary'
                      >
                        <span className='indicator-label'>Sign in</span>
                      </Button>
                    </div>

                    <div className='text-gray-500 text-center fw-semibold fs-6 mb-5'>
                      Not a Member yet?
                      <Link to='/' className='link-primary'>
                        {' '}
                        Sign up
                      </Link>
                    </div>

                    <div className='text-gray-500 text-center fw-semibold fs-6'>
                      Forgot your Password?
                      <Link to='/reset' className='link-primary'>
                        {' '}
                        Reset Password
                      </Link>
                    </div>

                    <div className='text-gray-500 text-center fw-semibold fs-6 mt-10'>
                      <a
                        target='new'
                        href='https://merchantstacks.com/terms-of-service/'
                        className='link-primary'
                      >
                        Terms of Service{' '}
                      </a>
                      <span className='w-15px'> | </span>
                      <a
                        target='new'
                        href='https://merchantstacks.com/privacy-policy/'
                        className='link-primary'
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
