import { Button } from 'baseui/button';
import { toaster } from 'baseui/toast';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';

export default function CreateMerchants() {
  const [form, setForm] = useState({
    name: '',
    merchantName: '',
    email: '',
    phone: '',
    password: ''
  }) as any;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({}) as any;

  const create = async () => {
    let newErrors = { ...errors };

    if (form.name === '') {
      newErrors = {
        ...newErrors,
        name: 'Name is required'
      };
    } else {
      newErrors = {
        ...newErrors,
        name: false
      };
    }

    if (form.merchantName === '') {
      newErrors = {
        ...newErrors,
        merchantName: 'Merchant Name is required'
      };
    } else {
      newErrors = {
        ...newErrors,
        merchantName: false
      };
    }

    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(form.email)) {
      newErrors = { ...newErrors, email: 'Please enter a valid email address' };
    } else {
      newErrors = { ...newErrors, email: false };
    }

    const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    if (!phoneRegex.test(form.phone)) {
      newErrors = { ...newErrors, phone: 'Please enter a valid phone number' };
    } else {
      newErrors = { ...newErrors, phone: false };
    }

    let passval = validatePassword(form.password);

    newErrors = { ...newErrors, password: passval };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error: any) => error !== false)) {
      return;
    }

    setLoading(true);

    try {
      let { data } = await Api.post('admin/create-user', {
        ...form,
        role: 'merchant'
      });

      toaster.positive(data.message);

      setLoading(false);

      setForm({
        name: '',
        merchantName: '',
        email: '',
        phone: '',
        password: ''
      });

      //go back
      window.history.back();
    } catch (error: any) {
      console.error(error);

      try {
        let { message } = error.response.data;

        if (message) {
          return toaster.negative(message);
        }
      } catch (e) {
        toaster.negative('An error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  function validatePassword(password: string) {
    var pass = [];

    if (!/(?=.*[a-z])/.test(password)) {
      pass.push('at least one lowercase letter');
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      pass.push('at least one uppercase letter');
    }
    if (!/(?=.*\d)/.test(password)) {
      pass.push('at least one number');
    }
    if (!/[!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?]+/g.test(password)) {
      pass.push('at least one special character');
    }
    if (password.length < 6) {
      pass.push('at least 6 characters long');
    }

    if (pass.length > 0) {
      //setPasswordError("Password is missing: " + errors.join(", "))
      //setErrors({ ...errors,  })
      return 'Password is missing: ' + pass.join(', ');
    } else {
      // setErrors({ ...errors, password: false })
      return false;
    }
  }

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar  py-6 '>
        <div
          id='kt_app_toolbar_container'
          className='app-container  container-xxl d-flex align-items-start '
        >
          <div className='d-flex flex-column flex-row-fluid'>
            <div className='d-flex align-items-center pt-1'>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold'>
                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/dashboard'
                    className='text-white text-hover-primary'
                  >
                    <i className='ki-outline ki-home text-white fs-3'></i>
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  <Link
                    to='/merchants'
                    className='text-white text-hover-primary'
                  >
                    Merchants
                  </Link>
                </li>

                <li className='breadcrumb-item'>
                  <i className='ki-outline ki-right fs-4 text-white mx-n1'></i>
                </li>

                <li className='breadcrumb-item text-white fw-bold lh-1'>
                  Create Merchants
                </li>
              </ul>
            </div>

            <div className='d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-6 pb-18 py-lg-13'>
              <div className='page-title d-flex align-items-center me-3'>
                <img
                  alt='Logo'
                  src='/assets/media/layer.svg'
                  className='h-60px me-5'
                />

                <h1 className='page-heading d-flex text-white fw-bolder fs-2 flex-column justify-content-center my-0'>
                  Merchants
                  <span className='page-desc text-white opacity-50 fs-6 fw-bold pt-4'>
                    Create Merchants
                  </span>
                </h1>
              </div>

              <div className='d-flex gap-4 gap-lg-13'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='app-container  container-xxl '>
        <div className='app-main flex-column flex-row-fluid '>
          <div className='d-flex flex-column flex-column-fluid'>
            <div className='app-content '>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body p-10'>
                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <label className='form-label mb-3'>Merchant Name</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                      placeholder='John Doe'
                    />
                    {errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div
                          data-field='name'
                          data-validator='notEmpty'
                          className='fv-help-block'
                        >
                          {errors.name}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <label className='form-label mb-3'>
                      Merchant Company Name
                    </label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      value={form.merchantName}
                      onChange={(e) =>
                        setForm({ ...form, merchantName: e.target.value })
                      }
                      placeholder='Merchant Stacks LLC'
                    />
                    {errors.merchantName && (
                      <div className='fv-plugins-message-container'>
                        <div
                          data-field='merchantName'
                          data-validator='notEmpty'
                          className='fv-help-block'
                        >
                          {errors.merchantName}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <label className='form-label mb-3'>Merchant Email</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='email'
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      placeholder='john@merchantstacks.com'
                    />
                    {errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div
                          data-field='email'
                          data-validator='notEmpty'
                          className='fv-help-block'
                        >
                          {errors.email}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <label className='form-label mb-3'>Merchant Phone</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      value={form.phone}
                      onChange={(e) =>
                        setForm({ ...form, phone: e.target.value })
                      }
                      placeholder='+1 123 456 7890'
                    />
                    {errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div
                          data-field='phone'
                          data-validator='notEmpty'
                          className='fv-help-block'
                        >
                          {errors.phone}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <label className='form-label mb-3'>Merchant Password</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='password'
                      value={form.password}
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      placeholder='Strong password'
                    />
                    {errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div
                          data-field='password'
                          data-validator='notEmpty'
                          className='fv-help-block'
                        >
                          {errors.password}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <Button
                      isLoading={loading}
                      onClick={create}
                      className='btn btn-lg btn-primary'
                    >
                      Create Merchant
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
